import React from "react";
import { Button, Col, Container, Form, FormLabel, Modal, Row, Table } from "react-bootstrap";
import { BsPlusLg, BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import { EditionContext } from "../../../../contexts/editionContext";
import { UIContext } from "../../../../contexts/uiContext";
import ParametroConstanteService from "../../../../services/parametro-constante-service";
import UtilsService from "../../../../services/utils-service";
import DatePicker from "../../../shared/date-picker";

const emptyParametro = {
  fechaHasta: new Date(),
  valor: ''
}

const emptyForm = {}

function ParametroConstanteNew() {

  const { setEdicion, objetoEdicion } = React.useContext(EditionContext)
  const [showModal, setShowModal] = React.useState(false)
  const [form, setForm] = React.useState(objetoEdicion ? objetoEdicion : emptyForm)
  const { showMessage } = React.useContext(UIContext)
  const [validated, setValidated] = React.useState(false);
  const formRef = React.useRef(null);
  const [parametrosConstantesList, setParametrosCostantesList] = React.useState(
    objetoEdicion && objetoEdicion.valores ? objetoEdicion.valores : [])
  const mainDivRef = React.useRef(null);

  const handleSuccess = message => {
    setShowModal(false);
    showMessage(message, 'success');
    setEdicion(false);
  }

  const clearForm = () => {
    setForm(emptyForm)
  }

  const handleFormValue = (name, value) => {
    const newForm = {
      ...form,
      [name]: value
    }
    setForm(newForm)
  }

  const submit = () => {
    const body = Object.assign({}, form)
    body.valores = parametrosConstantesList;
    if (form.id) {
      ParametroConstanteService.updateParametroConstante(body).then(parametro => {
        handleSuccess('Parametro actualizada.')
      })
    } else {
      ParametroConstanteService.createParametroConstante(body).then(data => {
        handleSuccess('Nuevo parametro creado.')
        clearForm();
      })
    }
  }

  function agregarParametroConstante() {
    const newParametrosList = [...parametrosConstantesList]
    const newEmptyField = Object.assign({}, emptyParametro);
    newParametrosList.unshift(newEmptyField)
    setParametrosCostantesList(newParametrosList)
  }

  function quitarParametroConstante(index) {
    const parametroRemove = [...parametrosConstantesList];
    parametroRemove.splice(index, 1)
    setParametrosCostantesList(parametroRemove);
  }

  function handleParametroConstanteValue(propiedad, nuevoValor, index) {
    const newParametrosList = [...parametrosConstantesList];

    const cambio = newParametrosList[index]

    cambio[propiedad] = nuevoValor;

    setParametrosCostantesList(newParametrosList);
  }

  function confirmar() {
    const form = formRef.current
    setShowModal(true)
    setValidated(true)
  }

  function disablear() {

    for (let i = 0; i < parametrosConstantesList.length; i++) {
      if (!parametrosConstantesList[i].fechaHasta || !parametrosConstantesList[i].valor) {
        return true
      }
    }
    if (parametrosConstantesList.length <= 0) {
      return true
    }

    return false;
  }

  function Control(props) {

    if (!props.value) {
      return <div>El campo está vacío</div>
    }
    return <div />
  }

  return (
    <div ref={mainDivRef}>
      <Container className="central-div">
        <h1>Nuevo Parámetro Constante</h1>
      </Container>
      <Container className="central-div">
        <Form autoComplete="off" className="central-div">
          <Row>
            <Col>
              <Form.Group controlId="nombre">
                <FormLabel>Nombre</FormLabel>
                <Form.Control type="text" name="nombre" value={form.nombre} required
                  onChange={e => handleFormValue('nombre', e.target.value)} />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <h4>Valores:</h4>
            <Table bordered size="sm">
              <thead>
                <tr>
                  <th>Vigente hasta:</th>
                  <th>Valor</th>
                  <th>
                    <Button onClick={() => agregarParametroConstante()}
                      title="Agregar valor" size="sm"
                    >
                      <BsPlusLg />
                    </Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {parametrosConstantesList.map((eachParametro, index) => {
                  return <tr key={index}>
                    <td>
                      <DatePicker
                        selected={eachParametro.fechaHasta}
                        onChange={date => handleParametroConstanteValue('fechaHasta', date, index)}
                      />
                      < Control value={eachParametro.fechaHasta} />
                    </td>
                    <td>
                      <Form.Control className="text-align-center" size="sm" type="text" value={eachParametro.valor} onChange={e => handleParametroConstanteValue('valor', UtilsService.numbersOnly(e.target.value), index)} />
                      <Control value={eachParametro.valor} />
                    </td>
                    <td>
                      <Button onClick={() => quitarParametroConstante(index)}
                        size="sm"
                        title="Quitar"
                        variant="danger">
                        <BsTrash />
                      </Button>
                    </td>
                  </tr>
                })}
              </tbody>
            </Table>
          </Row>
        </Form>
        <Row className="row-mb-5">
          <Col>
            <div className="central-div">
              <Link to={"/"}>
                <Button onClick={() => setEdicion(false)} size="lg" variant="danger" className="button-mr-5">
                  Cancelar
                </Button>
              </Link>
              <Button disabled={disablear()} size="lg" type="submit" onClick={() => {
                confirmar()
              }} >
                Confirmar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal}
        container={mainDivRef.current}
        onHide={() => setShowModal(false)}
        animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Seguro que quieres agregar o cambiar este parametro?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={submit}>
            Guardar parametro constante
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ParametroConstanteNew;
