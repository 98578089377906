import React from "react";
import { Button, Col, Form, FormLabel, Row } from "react-bootstrap";
import { BsFillPrinterFill } from "react-icons/bs";
import { IoRefreshOutline } from "react-icons/io5";
import { UIContext } from "../../../contexts/uiContext";
import CaratulaService from "../../../services/caratula-service";
import LiquidacionService from "../../../services/liquidacion-service";
import CaratulaSelector from "../../shared/caratula-selector";

let caratulaVigente = null;

function AutoImpresion() {

  const numDniRef = React.useRef()
  const numLegajoRef = React.useRef()
  const numClaveRef = React.useRef()

  const { showMessage, printDataUrl } = React.useContext(UIContext)
  const [caratula, setCaratula] = React.useState('')


  React.useEffect(() => {
    CaratulaService.getCaratulaVigente().then(data => {
      caratulaVigente = Object.assign({}, data);
      clearForm()
    })
  }, [])


  function submit(e) {
    e.preventDefault()

    const body = {
      dni: numDniRef.current.value,
      claveElectronica: numClaveRef.current.value,
      numeroLegajo: numLegajoRef.current.value,
      idCaratula: caratula.id
    }

    LiquidacionService.postAutoImpresion(body).then(dataUrl => {
      printDataUrl(dataUrl)
      clearForm()
    })
      .catch((error) => {
        showMessage('No se encontró recibo de sueldo', 'error')
      })
  }

  function clearForm() {
    setCaratula(caratulaVigente)
    numDniRef.current.value = ''
    numLegajoRef.current.value = ''
    numClaveRef.current.value = ''
    numDniRef.current.focus()
  }

  return (
    <div>
      <div className="auth-wrapper">
        <div className="auth-inner">
          <Form autoComplete="off" onSubmit={submit}>
            <Row >
              <h2>Impresión de recibo de sueldo</h2>
              <Form.Group controlId="dni" >
                <FormLabel>DNI</FormLabel>
                <Form.Control type="text" name="dni" ref={numDniRef} required />
              </Form.Group>
              <Form.Group controlId="numeroLegajo" >
                <FormLabel>Número de legajo</FormLabel>
                <Form.Control type="text" name="numeroLegajo" ref={numLegajoRef} required />
              </Form.Group>
              <Form.Group controlId="claveElectronica" >
                <FormLabel>Clave electrónica</FormLabel>
                <Form.Control type="password" name="claveElectronica" ref={numClaveRef} required />
              </Form.Group>
              <Form.Group controlId="caratula" >
                <FormLabel>Mes</FormLabel>
                <CaratulaSelector style={{ 'justifyContent': 'left' }} hideReload={true} value={caratula} onSelect={newCaratula => setCaratula(newCaratula)} />
              </Form.Group>
            </Row>
            <Row style={{ 'margin-top': '45px' }}>
              <Col>
                <Button title="Limpiar formulario" onClick={() => {
                  clearForm()
                }} size="l" variant="danger" className="button-mr-5">
                  <IoRefreshOutline />
                </Button>
                <Button type="submit" title="Imprimir" >
                  <BsFillPrinterFill />
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  )
}


export default AutoImpresion;