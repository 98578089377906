import React from 'react';
import { Col, Form, FormGroup, FormLabel, Table } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { BsPencil, BsPlusLg, BsTrash } from 'react-icons/bs';
import Row from "react-bootstrap/Row";
import { LegajoContext } from '../../../../contexts/legajoContext';
import SiNo from '../../../shared/si-no';
import UtilsService from '../../../../services/utils-service';
import DatePicker from '../../../shared/date-picker';

function FamiliarTab() {

  const { legajo, setLegajo } = React.useContext(LegajoContext);
  const [familiar, setFamiliar] = React.useState(legajo.familiares ? legajo.familiares : [])
  const [showFamiliarModal, setShowFamiliarModal] = React.useState(false)
  const formRef = React.useRef(null);
  const divRef = React.useRef(null);
  const [validated, setValidated] = React.useState(false);
  const [editionIndex, setEditionIndex] = React.useState(null)

  function handleFamiliarValue(name, value) {
    const newFamiliar = {
      ...familiar,
      [name]: value
    }
    setFamiliar(newFamiliar)
  }

  const parentescoOptions = [
    { value: '', label: '' },
    { value: 'CONCUBINA', label: 'Concubina' },
    { value: 'CONCUBINO', label: 'Concubino' },
    { value: 'ESPOSA', label: 'Esposa' },
    { value: 'ESPOSO', label: 'Esposo' },
    { value: 'HIJA', label: 'Hija' },
    { value: 'HIJO', label: 'Hijo' },
  ]

  const sexoOptions = [
    { value: '', label: '' },
    { value: 'MASCULINO', label: 'Masculino' },
    { value: 'FEMENINO', label: 'Femenino' },
    { value: 'OTRO', label: 'Otro' }
  ]

  const educacionOptions = [
    { value: '', label: '' },
    { value: 'PRIMARIA', label: 'Primaria' },
    { value: 'SECUNDARIA', label: 'Secundaria' },
    { value: 'UNIVERSITARIA_SUPERIOR', label: 'Universitaria o Superior' }
  ]

  function setFamiliares(familiares) {
    const newLegajo = {
      ...legajo,
      familiares: familiares
    }
    setLegajo(newLegajo);
  }

  function addFamiliar() {
    const newFamiliarList = [...legajo.familiares]
    newFamiliarList.push(familiar)
    setFamiliares(newFamiliarList);
  }

  function editFamiliar() {
    const newFamiliarList = [...legajo.familiares]
    newFamiliarList[editionIndex] = familiar
    setFamiliares(newFamiliarList)
  }

  function removeFamiliar(index) {
    const familiarRemove = [...legajo.familiares];
    familiarRemove.splice(index, 1)
    setFamiliares(familiarRemove);
  }

  function openEdicion(index) {
    setFamiliar(Object.assign({}, legajo.familiares[index]))
    setEditionIndex(index)
    setShowFamiliarModal(true)
  }

  function confirmarFamiliar() {
    if (editionIndex == -1) {
      addFamiliar()
    } else {
      editFamiliar()
    }
    setShowFamiliarModal(false);
  }

  return (
    <div ref={divRef}>
      <Modal show={showFamiliarModal}
        container={divRef.current}
        backdrop="static"
        onHide={() => setShowFamiliarModal(false)}
        size="lg"
        >
        <Modal.Header closeButton>
          <Modal.Title>Agregar familiar</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          <Form noValidate validated={validated}
            className="central-div"
            autoComplete="new-password"
            style={{ minHeight: '615px' }}
            ref={formRef}>
            <Row>
              <Col>
                <FormGroup>
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text"
                    autoComplete='new-password'
                    required
                    value={familiar.nombre}
                    onChange={e => handleFamiliarValue('nombre', UtilsService.lettersOnly(e.target.value))}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Form.Label>Apellido</Form.Label>
                  <Form.Control type="text"
                    autoComplete='new-password'
                    required
                    value={familiar.apellido}
                    onChange={e => handleFamiliarValue('apellido', UtilsService.lettersOnly(e.target.value))}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Form.Label>DNI</Form.Label>
                  <Form.Control type="text"
                    autoComplete="new-password"
                    required
                    value={familiar.dni}
                    onChange={e => handleFamiliarValue('dni', UtilsService.numbersOnly(e.target.value))}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Form.Label>Dirección</Form.Label>
                  <Form.Control type="text"
                    autoComplete="new-password"
                    required
                    value={familiar.direccion}
                    onChange={e => handleFamiliarValue('direccion', e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Form.Label>Sexo</Form.Label>
                  <Form.Select value={familiar.sexo}
                    onChange={e => {
                      handleFamiliarValue('sexo', e.target.value)
                    }}>
                    {sexoOptions.map(option =>
                      <option value={option.value}>{option.label}</option>
                    )}
                  </Form.Select>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <FormLabel>Fecha nacimiento</FormLabel>
                  <DatePicker
                    onChange={date => handleFamiliarValue('fechaNacimiento', date)}
                    selected={familiar.fechaNacimiento}
                  />
                </FormGroup>
                <FormGroup>
                  <br />
                  <Form.Label>Parentesco</Form.Label>
                  <Form.Select value={familiar.parentesco}
                    onChange={e => {
                      handleFamiliarValue('parentesco', e.target.value)
                    }}>
                    {parentescoOptions.map(option =>
                      <option value={option.value}>{option.label}</option>
                    )}
                  </Form.Select>
                </FormGroup>
              </Col>
              <Col md={4}>
                {
                  (familiar.parentesco === 'CONCUBINA' ||
                    familiar.parentesco === 'CONCUBINO' ||
                    familiar.parentesco === 'ESPOSA' ||
                    familiar.parentesco === 'ESPOSO') &&
                  <FormGroup>
                    <FormLabel>Fecha de inicio del vínculo</FormLabel>
                    <DatePicker
                      onChange={date => handleFamiliarValue('fechaInicioVinculo', date)}
                      selected={familiar.fechaInicioVinculo}
                    />
                  </FormGroup>
                }
                <FormGroup>
                  <Form.Label>Educación</Form.Label>
                  <Form.Select value={familiar.educacion}
                    onChange={e => {
                      handleFamiliarValue('educacion', e.target.value)
                    }}>
                    {educacionOptions.map(option =>
                      <option value={option.value}>{option.label}</option>
                    )}
                  </Form.Select>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup >
                  <FormLabel>Capacidades especiales</FormLabel>
                  <SiNo value={familiar.capacidadesDiferentes} onSelect={value => handleFamiliarValue('capacidadesDiferentes', value)} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup >
                  <FormLabel>Estudiante con certificado</FormLabel>
                  <SiNo value={familiar.estudianteConCertificado} onSelect={value => handleFamiliarValue('estudianteConCertificado', value)} />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFamiliarModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={() => confirmarFamiliar()
          }>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal >
      <Table bordered size="sm">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>DNI</th>
            <th>Sexo</th>
            <th>Dirección</th>
            <th>Editar</th>
            <th>Eliminar</th>
            <th>
              <Button onClick={() => {
                setEditionIndex(-1)
                setFamiliar({})
                setShowFamiliarModal(true)
              }}
                size="sm"
                title="Agregar familiar"
              >
                <BsPlusLg />
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {legajo && legajo.familiares && legajo.familiares.map((eachFamiliar, index) =>
            <tr key={index}>
              <td>{eachFamiliar.nombre}</td>
              <td>{eachFamiliar.apellido}</td>
              <td>{eachFamiliar.dni}</td>
              <td>{eachFamiliar.sexo}</td>
              <td>{eachFamiliar.direccion}</td>
              <td>
                <Button onClick={() => openEdicion(index)}
                  size="sm"
                  title="Modificar">
                  <BsPencil />
                </Button>
              </td>
              <td>
                <Button onClick={() => removeFamiliar(index)}
                  size="sm"
                  title="Quitar"
                  variant="danger">
                  <BsTrash />
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div >
  )
}

export default FamiliarTab;