import React from 'react';
import { EditionContext } from '../../../contexts/editionContext';
import CategoriaList from './list';
import CategoriaNew from './new';

function Categoria() {

  const { edicion } = React.useContext(EditionContext)

  return (
    edicion === true ?
      <CategoriaNew /> : <CategoriaList />
  )
}

export default Categoria;