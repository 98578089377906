import React from "react";
import { EditionContext } from "../../../contexts/editionContext";
import LiquidacionList from "./list";
import LiquidacionNew from "./new";


function Liquidacion() {

  const { edicion } = React.useContext(EditionContext);

  return (
    edicion === true ?
      <LiquidacionNew /> : <LiquidacionList />
  )
}

export default Liquidacion;