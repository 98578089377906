import React from "react";
import { UIContext } from "../../contexts/uiContext";
import CaratulaService from "../../services/caratula-service";
import BaseDarkSelect from "./base-dark-select";

function CaratulaSelector(props) {

  const [caratulasOptions, setCaratulasOptions] = React.useState({});
  const { showMessage } = React.useContext(UIContext);

  React.useEffect(() => {
    buscar()
  }, [])

  function buscar() {
    CaratulaService.getCaratulas(1, 500)
      .then(page => {
        setCaratulasOptions(page.content.map(caratula => ({ value: caratula, label: caratula.descripcion })))
      })
      .catch((error) => {
        showMessage('Ocurrió un error al recuperar las caratulas', 'error')
      })
  }

  return (
    <BaseDarkSelect options={caratulasOptions}
      value={{ value: props.value, label: props.value ? props.value.descripcion : '' }}
      required
      isClearable
      onChange={e => props.onSelect(e ? e.value : null)} />
  )
}

export default CaratulaSelector;