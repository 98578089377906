import React from 'react';
import { EditionContext } from '../../../contexts/editionContext';
import ConceptoLiquidacionList from './list';
import ConceptoLiquidacionNew from './new';


function ConceptoLiquidacion() {

  const { edicion } = React.useContext(EditionContext)

  return (
    edicion === true ?
      <ConceptoLiquidacionNew /> : <ConceptoLiquidacionList />
  )
}

export default ConceptoLiquidacion;