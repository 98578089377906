import React from 'react'
import { Card, Col, Container, Form, FormLabel, Table } from 'react-bootstrap'
import Row from "react-bootstrap/Row";
import ConceptoLiquidacionService from '../../../../services/concepto-liquidacion-service';
import { UIContext } from '../../../../contexts/uiContext'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { Link, useParams } from "react-router-dom";
import { EditionContext } from '../../../../contexts/editionContext'
import SiNo from '../../../shared/si-no';
import { NumericFormat } from 'react-number-format';
import TipoLiquidacionSelector from '../../../shared/tipo-liquidacion-selector';
import ConceptoLiquidacionSelector from "../../../shared/concepto-liquidacion-selector";
import { BsArrow90DegRight, BsTrash } from 'react-icons/bs';


const emptyForm = {
  formula: '',
  remunerativo: '',
  bonificable: '',
  tiposLiquidacion: []
};

function ConceptoLiquidacionNew() {

  const { setEdicion, objetoEdicion } = React.useContext(EditionContext)

  const [form, setForm] = React.useState(objetoEdicion ? objetoEdicion : emptyForm)
  const [showModal, setShowModal] = React.useState(false)
  const { showMessage } = React.useContext(UIContext)
  const [validated, setValidated] = React.useState(false);
  const formRef = React.useRef(null);
  const mainDivRef = React.useRef(null);
  const [conceptosExcluyentes, setConceptosExcluyentes] = React.useState(objetoEdicion?.conceptosExcluyentes ? objetoEdicion.conceptosExcluyentes : []) //debe empezar con los conceptos de objetoEdicion
  const [conceptoLiquidacionSeleccionado, setConceptoLiquidacionSeleccionado] = React.useState(null);
  const CODIGO_MAX_LIMIT = 10000;
  const CODIGO_MAX_LENGTH = 5;

  const tiposConceptoLiquidacion = [
    { label: '', value: '' },
    { label: "Haber", value: 'HABER' },
    { label: "Descuento", value: 'DESCUENTO' },
    { label: "Contribución", value: 'CONTRIBUCION' }
  ]

  const handleSuccess = message => {
    setShowModal(false);
    showMessage(message, 'success');
    setEdicion(false);
  }

  const clearForm = () => {
    setForm(emptyForm)
  }

  const submit = () => {
    const body = Object.assign({}, form);
    body.tiposLiquidacion = body.tiposLiquidacion.map((optionTipo) => optionTipo.value);
    body.conceptosExcluyentesIds = conceptosExcluyentes.map(concepto => concepto.id);

    if (form.id) {
      ConceptoLiquidacionService.updateConceptoLiquidacion(body).then(conceptoLiquidacion => {
        handleSuccess('Concepto de liquidación actualizado')
      })
    } else {
      ConceptoLiquidacionService.createConceptoLiquidacion(body).then(data => {
        handleSuccess('Nuevo concepto de liquidación.')
        clearForm()
      })
    }
  }

  const handleFormValue = (name, value) => {
    const newForm = {
      ...form,
      [name]: value
    }
    setForm(newForm)
  }

  function confirmar() {
    const form = formRef.current
    if (form.checkValidity()) {
      setShowModal(true)
    }
    setValidated(true)
  }

  function onCodigoOnBlur() {
    const paddedValue = form.codigo.toString().padStart(4, "0")
    handleFormValue('codigo', paddedValue);
  }

  function addConceptoLiquidacion() {
    //si ya existe el concepto en la lista, no hacemos nada
    if (conceptosExcluyentes.find(concepto => concepto.id === conceptoLiquidacionSeleccionado.id)) {
      setConceptoLiquidacionSeleccionado(null)
      return;
    }
    const excluyentes = [...conceptosExcluyentes];
    excluyentes.push(conceptoLiquidacionSeleccionado);
    setConceptosExcluyentes(excluyentes);
    setConceptoLiquidacionSeleccionado(null)
  }

  function removeConceptoExcluyente(index) {
    const newConceptosExcluyentes = [...conceptosExcluyentes];
    newConceptosExcluyentes.splice(index, 1)
    setConceptosExcluyentes(newConceptosExcluyentes)
  }

  return (
    <div ref={mainDivRef}>
      <Container className="central-div">
        <div className="title-and-buttons">
          <h1 className="title">Crear nuevo concepto de liquidación</h1>
        </div>
      </Container>
      <Container className="central-div">
        <Form noValidate validated={validated} ref={formRef} className="central-div" autoComplete="off">
          <Row>
            <Col md={2}>
              <Form.Group>
                <FormLabel>Código</FormLabel>
                <NumericFormat
                  customInput={Form.Control}
                  value={form.codigo}
                  allowLeadingZeros
                  isAllowed={(values) => {
                    const { floatValue, value } = values;
                    return (
                      floatValue === null ||
                      floatValue === undefined ||
                      (value.length < CODIGO_MAX_LENGTH && floatValue < CODIGO_MAX_LIMIT)
                    );
                  }}
                  onChange={(e) => handleFormValue('codigo', e.target.value)}
                  onBlur={onCodigoOnBlur}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="nombre">
                <FormLabel>Nombre</FormLabel>
                <Form.Control
                  type="text"
                  name="nombre"
                  value={form.nombre}
                  required
                  onChange={(e) => handleFormValue('nombre', e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group controlId="tipoConceptoLiquidacion">
                <FormLabel>Tipo de concepto</FormLabel> <br />
                <Form.Select
                  className="selector"
                  value={form.tipoConceptoLiquidacion}
                  onChange={(e) => handleFormValue('tipoConceptoLiquidacion', e.target.value)}
                >
                  {tiposConceptoLiquidacion.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group controlId="tiposLiquidacion">
                <FormLabel>Tipo de Liquidacion</FormLabel> <br />
                <TipoLiquidacionSelector
                  value={form.tiposLiquidacion}
                  isMulti={true}
                  onSelect={(value) => {
                    handleFormValue('tiposLiquidacion', value);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Group controlId="bonificable">
                <FormLabel htmlFor="bonificable">Bonificable</FormLabel>
                <SiNo id="bonificable" dropdown={true} value={form.bonificable} onSelect={(value) => handleFormValue('bonificable', value)} />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="remunerativo">
                <FormLabel htmlFor="remunerativo">Remunerativo</FormLabel>
                <SiNo id="remunerativo" dropdown={true} value={form.remunerativo} onSelect={(value) => handleFormValue('remunerativo', value)} />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="descuentoLey">
                <FormLabel htmlFor="descuentoLey">Descuento de ley</FormLabel>
                <SiNo id="descuentoLey" dropdown={true} value={form.descuentoLey} onSelect={(value) => handleFormValue('descuentoLey', value)} />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="asignacionFamiliar">
                <FormLabel htmlFor="asignacionFamiliar">Asignación Familiar</FormLabel>
                <SiNo id="asignacionFamiliar" dropdown={true} value={form.asignacionFamiliar} onSelect={(value) => handleFormValue('asignacionFamiliar', value)} />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Container>
      <Container className="central-div">
        <Form noValidate validated={validated} ref={formRef} className="central-div" autoComplete="off">
          <Row>
            <Form.Group controlId="formula">
              <FormLabel>Fórmula</FormLabel>
              <textarea
                className="form-control"
                type="text"
                rows="5"
                name="formula"
                value={form.formula}
                required
                onChange={(e) => handleFormValue('formula', e.target.value)}
              ></textarea>
            </Form.Group>
          </Row>
        </Form>
      </Container>
      <Container className="central-div">
        <Form noValidate validated={validated} ref={formRef} className="central-div" autoComplete="off">
          <Row>
            <Col md={10}>
              <Form.Group>
                <Form.Label>Seleccionar conceptos a excluir</Form.Label>
                <ConceptoLiquidacionSelector
                  value={conceptoLiquidacionSeleccionado}
                  onSelect={(newConceptoLiquidacion) => {
                    setConceptoLiquidacionSeleccionado(newConceptoLiquidacion);
                  }}
                />
              </Form.Group>
            </Col>
            <Col
              md={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '26px',
              }}
            >
              <Button disabled={!conceptoLiquidacionSeleccionado} onClick={() => addConceptoLiquidacion()}>
                <BsArrow90DegRight style={{ transform: 'rotate(90deg)' }} />
              </Button>
            </Col>
          </Row>
          <Row>
            <Table bordered size="sm">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Codigo</th>
                </tr>
              </thead>
              <tbody>
                {conceptosExcluyentes.map((conceptoExcluido, index) => {
                  return (
                    <tr key={conceptoExcluido.codigo}>
                      <td>{conceptoExcluido.nombre}</td>
                      <td>{conceptoExcluido.codigo}</td>
                      <td>
                        <Button onClick={() => removeConceptoExcluyente(index)}
                          size="sm"
                          title="Quitar"
                          variant="danger">
                          <BsTrash />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Row>
        </Form>
      </Container>
      <Container className="central-div">
        <Row className="row-mb-5">
          <Col>
            <div className="central-div">
              <Link to="/">
                <Button onClick={() => setEdicion(false)} size="lg" variant="danger" className="button-mr-5">
                  Cancelar
                </Button>
              </Link>
              <Button size="lg" type="submit" onClick={() => confirmar()}>
                Confirmar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={showModal} onHide={() => setShowModal(false)} animation={false} container={mainDivRef.current}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Seguro que quieres agregar o cambiar este concepto?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={submit}>
            Guardar concepto
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

}

export default ConceptoLiquidacionNew;