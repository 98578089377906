import React from 'react'
import { Col, Container, Form, FormLabel, Table } from 'react-bootstrap'
import Row from "react-bootstrap/Row";
import { UIContext } from '../../../../contexts/uiContext'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { Link } from "react-router-dom";
import { EditionContext } from '../../../../contexts/editionContext'
import CategoriaService from '../../../../services/categoria-service';
import { BsPlusLg, BsTrash } from 'react-icons/bs';
import CurrencyInput from '../../../shared/currency-input';
import DatePicker from '../../../shared/date-picker';


const emptyForm = {
  nombre: '',
  descripcion: ''
}

const emptySueldo = {
  fechaHasta: new Date(),
  valor: ''
}

function CategoriaNew() {

  const { setEdicion, objetoEdicion } = React.useContext(EditionContext)

  const [form, setForm] = React.useState(objetoEdicion ? objetoEdicion : emptyForm)
  const [showModal, setShowModal] = React.useState(false)
  const { showMessage } = React.useContext(UIContext)
  const [validated, setValidated] = React.useState(false);
  const formRef = React.useRef(null);
  const [sueldosBasicosList, setSueldosBasicosList] = React.useState(
    objetoEdicion && objetoEdicion.sueldosBasicos ? objetoEdicion.sueldosBasicos : [])
  const mainDivRef = React.useRef(null);



  const handleSuccess = message => {
    setShowModal(false);
    showMessage(message, 'success');
    setEdicion(false);
  }

  const clearForm = () => {
    setForm(emptyForm)
  }

  const submit = () => {
    const body = Object.assign({}, form)
    body.sueldosBasicos = sueldosBasicosList;

    if (form.id) {
      CategoriaService.updateCategoria(body).then(categoria => {
        handleSuccess('Categoría actualizada.')
      })
    } else {
      CategoriaService.createCategoria(body).then(data => {
        handleSuccess('Nueva categoría creada.')
        clearForm();
      })
    }
  }

  const handleFormValue = (name, value) => {
    const newForm = {
      ...form,
      [name]: value
    }
    setForm(newForm)
  }

  function Control(props) {

    if (!props.value) {
      return <div>El campo está vacío</div>
    }
    return <div />
  }

  function disablear() {

    for (let i = 0; i < sueldosBasicosList.length; i++) {
      if (!sueldosBasicosList[i].fechaHasta || !sueldosBasicosList[i].valor) {
        return true
      }
    }
    if (sueldosBasicosList.length <= 0) {
      return true
    }

    return false;
  }

  function handleSueldoBasicoValue(propiedad, nuevoValor, index) {
    const newSueldosBasicosList = [...sueldosBasicosList];
    const cambio = newSueldosBasicosList[index]
    cambio[propiedad] = nuevoValor;
    setSueldosBasicosList(newSueldosBasicosList);
  }

  function agregarSueldoBasico() {
    const newSueldosBasicosList = [...sueldosBasicosList]
    const newEmptySueldo = Object.assign({}, emptySueldo);
    newSueldosBasicosList.push(newEmptySueldo)
    setSueldosBasicosList(newSueldosBasicosList)
  }

  function quitarSueldoBasico(index) {
    const sueldoBasicoRemove = [...sueldosBasicosList];
    sueldoBasicoRemove.splice(index, 1)
    setSueldosBasicosList(sueldoBasicoRemove);
  }

  function confirmar() {
    const form = formRef.current
    if (form.checkValidity()) {
      setShowModal(true)
    }
    setValidated(true)
  }

  return (
    <div ref={mainDivRef}>
      <Container className="central-div">
        <h1>Cargar nueva categoría</h1>
      </Container>
      <Container className="central-div">
        <Form noValidate validated={validated}
          ref={formRef}
          className="central-div"
          autoComplete="off" >
          <Row>
            <Col md={1}>
              <Form.Group controlId="indice">
                <FormLabel>Indice</FormLabel>
                <Form.Control type="text" name="indice" value={form.indice} required
                  onChange={e => handleFormValue('indice', e.target.value)} />
              </Form.Group>
            </Col>
            <Col md={5}>
              <Form.Group controlId="nombre">
                <FormLabel>Nombre</FormLabel>
                <Form.Control type="text" name="nombre" value={form.nombre} required
                  onChange={e => handleFormValue('nombre', e.target.value)} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="descripcion">
                <FormLabel>Descripcion</FormLabel>
                <Form.Control type="text" name="descripcion" value={form.descripcion}
                  onChange={e => handleFormValue('descripcion', e.target.value)} />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <h4>Sueldo básico:</h4>
            <Table bordered size="sm">
              <thead>
                <tr>
                  <th>Vigente hasta</th>
                  <th>Valor</th>
                  <th>
                    <Button onClick={() => agregarSueldoBasico()}
                      size="sm"
                    >
                      <BsPlusLg />
                    </Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sueldosBasicosList.map((eachSueldo, index) => {
                  return <tr key={index}>
                    <td>
                      <DatePicker
                        selected={eachSueldo.fechaHasta}
                        onChange={date => handleSueldoBasicoValue('fechaHasta', date, index)}
                      />
                      <Control value={eachSueldo.fechaHasta} />
                    </td>
                    <td>
                      <CurrencyInput value={eachSueldo.valor}
                        onValueChange={value => handleSueldoBasicoValue('valor', value.floatValue, index)}
                      />
                      <Control value={eachSueldo.valor} />
                    </td>
                    <td>
                      <Button onClick={() => quitarSueldoBasico(index)}
                        size="sm"
                        title="Quitar"
                        variant="danger">
                        <BsTrash />
                      </Button>
                    </td>
                  </tr>
                }
                )}
              </tbody>
            </Table>
          </Row>
        </Form>
        <Row className="row-mb-5">
          <Col>
            <div className="central-div">
              <Link to={"/"}>
                <Button onClick={() => setEdicion(false)} size="lg" variant="danger" className="button-mr-5">
                  Cancelar
                </Button>
              </Link>
              <Button disabled={disablear()} size="lg" type="submit" onClick={() => {
                confirmar()
              }} >
                Confirmar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={showModal}
        container={mainDivRef.current}
        onHide={() => setShowModal(false)}
        animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Seguro que quieres agregar o cambiar esta categoría?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={submit}>
            Guardar categoría
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}

export default CategoriaNew;