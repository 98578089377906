import React from 'react'
import { Col, Container, Form, FormLabel } from 'react-bootstrap'
import Row from "react-bootstrap/Row";
import { UIContext } from '../../../../contexts/uiContext'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { Link, useParams } from "react-router-dom";
import { EditionContext } from '../../../../contexts/editionContext'
import CaratulaService from '../../../../services/caratula-service';
import moment from 'moment';
import TipoLiquidacionSelector from '../../../shared/tipo-liquidacion-selector';
import DatePicker from '../../../shared/date-picker';

const emptyForm = {
  descripcion: '',
  fechaPago: '',
  observaciones: '',
  fechaPago: new Date(),
  periodoPago: new Date(),
  tipoLiquidacion: ''
};

function CaratulaNew() {

  const { setEdicion, objetoEdicion } = React.useContext(EditionContext);
  const [form, setForm] = React.useState(objetoEdicion ? objetoEdicion : emptyForm)
  const [showModal, setShowModal] = React.useState(false)
  const { showMessage } = React.useContext(UIContext)
  const [validated, setValidated] = React.useState(false);
  const formRef = React.useRef(null);
  const mainDivRef = React.useRef(null);

  const handleSuccess = message => {
    setShowModal(false);
    showMessage(message, 'success');
    setEdicion(false);
  }

  const clearForm = () => {
    setForm(emptyForm)
  }

  const submit = () => {
    const body = Object.assign({}, form)
    body.anioPago = moment(body.periodoPago).format('YYYY');
    body.mesPago = moment(body.periodoPago).format("M")

    if (form.id) {
      CaratulaService.updateCaratula(body).then(caratula => {
        handleSuccess('Carátula actualizada')
      })
    } else {
      CaratulaService.createCaratula(body).then(data => {
        handleSuccess('Nueva carátula creada.')
        clearForm()
      })
    }
  }

  const handleFormValue = (name, value) => {
    const newForm = {
      ...form,
      [name]: value
    }
    setForm(newForm)
  }

  function confirmar() {
    const form = formRef.current
    if (form.checkValidity()) {
      setShowModal(true)
    }
    setValidated(true)
  }

  return (
    <div ref={mainDivRef}>
     <Container className="central-div">
        <div className="title-and-buttons">
          <h1 className="title">Crear nueva carátula.</h1>
        </div>
      </Container>
      <Container className="central-div">
        <Form noValidate validated={validated}
          ref={formRef}
          className="central-div"
          autoComplete="off" >
          <Row>
            <Col md={6}>
              <h4>fecha de pago</h4>
              <DatePicker
                selected={form.fechaPago}
                onChange={date => handleFormValue('fechaPago', date)}
              />
              <h4>Período de pago</h4>
              <DatePicker
                selected={form.periodoPago}
                onChange={date => {
                  handleFormValue('periodoPago', date)
                }}
              />
            </Col>
            <Col md={6}>
              <Form.Group controlId="descripcion">
                <FormLabel>Descripción</FormLabel>
                <Form.Control type="text" name="descripcion" value={form.descripcion} required
                  onChange={e => handleFormValue('descripcion', e.target.value)} />
              </Form.Group>
              <Form.Group controlId="tipoLiquidacion">
                <FormLabel>Tipo de liquidacion</FormLabel>
                <TipoLiquidacionSelector value={form.tipoLiquidacion}
                  required
                  isMulti={false}
                  onSelect={value => {
                    handleFormValue('tipoLiquidacion', value)
                  }} />
              </Form.Group>
              <Form.Group controlId="observaciones">
                <FormLabel>Observaciones</FormLabel>
                <Form.Control type="text" name="observaciones" value={form.observaciones} required
                  onChange={e => handleFormValue('observaciones', e.target.value)} />
              </Form.Group>
              <Form.Group controlId="infoDeAportes">
                <FormLabel>Información de aportes</FormLabel>
                <textarea className='form-control' type="text" rows="5" name="infoDeAportes" value={form.infoDeAportes}
                  onChange={e => handleFormValue('infoDeAportes', e.target.value)}></textarea>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <Row className="row-mb-5">
          <Col>
            <div className="central-div">
              <Link to={"/"}>
                <Button onClick={() => setEdicion(false)} size="lg" variant="danger" className="button-mr-5">
                  Cancelar
                </Button>
              </Link>
              <Button size="lg" type="submit" onClick={() => { confirmar() }} >
                Confirmar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={() => setShowModal(false)} animation={false} container={mainDivRef.current}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>{form.id ? "modificar esta carátula?" : "Agregar nueva carátula"}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={submit}>
            Guardar carátula
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CaratulaNew;