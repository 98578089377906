import React from 'react';

export const EditionContext = React.createContext(null);

export const EditionProvider = (props) => {

  const [edicion, setEdicion] = React.useState(false)
  
  const [objetoEdicion, setObjetoEdicion] = React.useState(null)

  return (
    <EditionContext.Provider value={{ edicion, setEdicion, objetoEdicion, setObjetoEdicion }}>
      {props.children}
    </EditionContext.Provider>
  )
}