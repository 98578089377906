import axios from 'axios';

const ClasificadorLegajoService = {

  getClasificadores: () => {
    return axios.get('/api/liquisueldos/clasificador-legajo').then(resp => resp.data);
  },
  getClasificador: id => {
    return axios.get('/api/liquisueldos/clasificador-legajo/' + id).then(resp => resp.data);
  }
}

export default ClasificadorLegajoService;
