import React from 'react';


export const LegajoContext = React.createContext(null)

export const LegajoProvider = (props) => {

  const { legajo, setLegajo } = props;


  return (

    <LegajoContext.Provider value={{ legajo, setLegajo }}>
      {props.children}
    </LegajoContext.Provider>

  )
}

