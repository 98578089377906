import React from 'react';
import LegajoList from './list';
import LegajoNew from './new';
import { EditionContext } from '../../../contexts/editionContext';


function Legajo() {

  const { edicion } = React.useContext(EditionContext)

  return (
    edicion === true ?
      <LegajoNew /> : <LegajoList />
  )
}

export default Legajo;