import React from "react"
import { Button, Modal, Table } from "react-bootstrap";
import LegajoSelector from "../../../shared/legajo-selector";


function ConceptoCloningModal(props) {

  const [valoresConceptoLiquidacion, setValoresConceptoLiquidacion] = React.useState(null);

  function buscarConceptosLegajo(legajo) {
    setValoresConceptoLiquidacion(legajo.valoresConceptoLiquidacion);
  }

  function aceptarModal() {

    const nuevosValores = valoresConceptoLiquidacion.map(eachValor => {
      const valorClon = Object.assign({}, eachValor)

      const relacionConcepto = eachValor.relaciones.find(eachRelacion => eachRelacion.type === "concepto_liquidacion")
      valorClon.conceptoLiquidacion = {
        nombre: relacionConcepto.metadata.nombre,
        codigo: relacionConcepto.metadata.codigo,
        id: relacionConcepto.id
      }
      valorClon.id = null;
      delete valorClon.relaciones;
      return valorClon;
    })
    props.setValoresLegajo(nuevosValores);
    props.setShowConceptoCloningModal(false)
  }

  return (
    <div>
      <Modal show={props.showConceptoCloningModal} onHide={() => props.setShowConceptoCloningModal(false)} animation={false} container={props.container}>
        <Modal.Header>
          <Modal.Title>Legajo copy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LegajoSelector onSelect={legajo => buscarConceptosLegajo(legajo)
          } readOnly={true} />
          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Código</th>
                <th>Porcentaje</th>
                <th>Cantidad</th>
                <th>Importe</th>
              </tr>
            </thead>
            <tbody>
              {valoresConceptoLiquidacion ? valoresConceptoLiquidacion.map((eachValorConcepto, index) => {
                const conceptoLiquidacion = eachValorConcepto.relaciones.find(eachRelacion => eachRelacion.type === "concepto_liquidacion")
                return <tr key={index} >
                  <td>{conceptoLiquidacion.metadata.nombre}</td>
                  <td>{conceptoLiquidacion.metadata.codigo}</td>
                  <td>{eachValorConcepto.porcentaje}</td>
                  <td>{eachValorConcepto.cantidad} </td>
                  <td>{eachValorConcepto.importe} </td>
                </tr>
              }) : <tr><td colSpan={5}>No hay conceptos para mostrar</td></tr>}
            </tbody>
          </Table>


        </Modal.Body>
        <Modal.Footer>
          <Button variante="secondary" onClick={() => {
            props.setShowConceptoCloningModal(false)
            setValoresConceptoLiquidacion(null)
          }}>
            Cerrar
          </Button>
          <Button variant="primary" 
            disabled={valoresConceptoLiquidacion === null || valoresConceptoLiquidacion === undefined || valoresConceptoLiquidacion.length === 0}
            onClick={aceptarModal}
          >
            Copiar lista de conceptos
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )

}
export default ConceptoCloningModal;


