import React from 'react'
import { Button, Container } from 'react-bootstrap';
import Row from "react-bootstrap/Row";
import { EditionContext } from '../../../../contexts/editionContext'
import ConceptoLiquidacionService from '../../../../services/concepto-liquidacion-service';
import AdminTable from '../../../shared/admin-table';
import { BsPencil, BsPlusLg } from 'react-icons/bs';
import { textFilter } from 'react-bootstrap-table2-filter';


function ConceptoLiquidacionList() {
  const { setEdicion, setObjetoEdicion } = React.useContext(EditionContext)
  const [conceptoLiquidacion, setConceptoLiquidacionPage] = React.useState({ content: [] });
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [filters, setFilters] = React.useState({});

  React.useEffect(() => {
    ConceptoLiquidacionService.getConceptosLiquidacion(page, pageSize, filters).then(data => { setConceptoLiquidacionPage(data) })
  }, [page, pageSize, filters.nombre, filters.codigo])


  function modificarConcepto(row) {
    const objeto = Object.assign({}, row);
    if (objeto.tiposLiquidacion) {
      objeto.tiposLiquidacion = row.tiposLiquidacion.map(tipo => {
        const tiposLiquidacionOption = {}
        tiposLiquidacionOption.value = tipo;
        tiposLiquidacionOption.label = tipo;
        return tiposLiquidacionOption;
      })
    }
    setObjetoEdicion(objeto);
    setEdicion(true)
  }

  function onTableChange(type, newState) {
    if (type === 'filter') {
      setFilters(() => {
        const newFilter = {};
        for (const filterName in newState.filters) {
          const filterObject = newState.filters[filterName];
          const value = filterObject.filterType === 'NUMBER' ?
            filterObject.filterVal.number :
            filterObject.filterVal;
          if (value) {
            newFilter[filterName] = value;
          }
        }
        return newFilter;
      })
    }
    return null;
  }

  const columns = [
    {
      dataField: 'nombre',
      text: 'Nombre',
      filter: textFilter({
        style: { display: 'inline-grid' },
      }),
    },
    {
      dataField: 'codigo',
      text: 'Código',
      filter: textFilter({
        style: { display: 'inline-grid' },
      }),
    },
    {
      isDummyField: true,
      text: 'Modificar',
      formatter: (cell, row, rowIndex) => {
        return <Button
          size="sm"
          title="Modificar"
          onClick={() => modificarConcepto(row)}>
          <BsPencil />
        </Button>
      }
    }
  ];

  return (
    <div>
      <Container className="central-div" >
        <div className="title-and-buttons">
          <h1 className="title">Conceptos de liquidación</h1>
          <span className="buttons">
            <Button onClick={() => { setEdicion(true); setObjetoEdicion(null) }}
              title="Nuevo Concepto"
            >
              <BsPlusLg />
            </Button>
          </span>
        </div>
      </Container>
      <Container className="central-div">
        <Row>
          <AdminTable data={conceptoLiquidacion.content}
            columns={columns}
            totalSize={conceptoLiquidacion.totalElements}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            onTableChange={onTableChange}
          />
        </Row>
      </Container>
    </div>
  )
}

export default ConceptoLiquidacionList