import React from 'react'
import { Button, Container } from 'react-bootstrap';
import Row from "react-bootstrap/Row";
import { EditionContext } from '../../../../contexts/editionContext'
import CategoriaService from '../../../../services/categoria-service';
import AdminTable from '../../../shared/admin-table';
import { BsPencil, BsPlusLg } from 'react-icons/bs';

function CategoriaList() {

  const { setEdicion, setObjetoEdicion } = React.useContext(EditionContext)
  const [categoriaPage, setCategoriaPage] = React.useState({ content: [] });
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  React.useEffect(() => {
    CategoriaService.getCategorias(page, pageSize).then(data => {
      setCategoriaPage(data)
    })
  }, [page, pageSize]);

  const columns = [
    { dataField: 'nombre', text: 'Nombre' },
    { dataField: 'descripcion', text: 'Descripción' },
    { dataField: 'indice', text: 'Indice' },
    {
      isDummyField: true,
      text: 'Sueldo Básico actual',
      formatter: (cell, row, rowIndex) => {
        if (row.sueldosBasicos) {
          const today = new Date();
          const sueldosFiltrados = row.sueldosBasicos.filter((sueldo) => sueldo.fechaHasta >= today)
          return <div>{sueldosFiltrados.length > 0 ? sueldosFiltrados[0].valor : row.sueldosBasicos[row.sueldosBasicos.length - 1].valor}</div>
        }
        return <div> 0.00</div>
      },
    },
    {
      isDummyField: true,
      text: 'Modificar',
      formatter: (cell, row, rowIndex) => {
        return <Button
          title='Modificar'
          size="sm"
          onClick={() => {
            const objeto = Object.assign({}, row);
            setObjetoEdicion(objeto);
            setEdicion(true)
          }}
        >
          <BsPencil />
        </Button>
      }
    }
  ];

  return (
    <div>
      <Container className="central-div" >
        <div className="title-and-buttons">
          <h1 className="title">Categorías</h1>
          <span className="buttons">
            <Button onClick={() => { setEdicion(true); setObjetoEdicion(null) }}
              title="Nueva Categoría"
            >
              <BsPlusLg />
            </Button>
          </span>
        </div>
      </Container>
      <Container className="central-div">
        <Row>
          <AdminTable data={categoriaPage.content}
            columns={columns}
            totalSize={categoriaPage.totalElements}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </Row>
      </Container>

    </div>

  )
}

export default CategoriaList;