import React from 'react';
import { Button, Card, Container, Form, FormLabel, Row } from 'react-bootstrap';
import LegajoService from '../../services/legajo-service';
import { Col } from 'react-bootstrap';
import { ImCancelCircle } from "react-icons/im";

function LegajoSelector(props) {


  const [legajo, setLegajo] = React.useState(props.legajo ? props.legajo : null)
  const [inputValue, setInputValue] = React.useState('')


  function buscarLegajo() {
    LegajoService.getPersonaPorNumero(inputValue).then(data => {
      setLegajo(data);
      if (props.onSelect) {
        props.onSelect(data)
      }
    })
  }

  function checkKeyPress(event) {
    if (event.key === 'Enter') {
      buscarLegajo();
    }
  }

  return (
    <Container style={{ 'color-text': 'black' }}>
      {legajo ? <div>
        <span style={{ cursor: 'pointer' }}  >
          {!props.readOnly && <Row>
            <ImCancelCircle onClick={() => {
              setLegajo(null)
              setInputValue('');
            }} />
          </Row>}
        </span>
        <Row>
          {!legajo.activo && <Button variant="danger">Legajo inactivo</Button>}
        </Row>
        <Row>
          <Col>
            <FormLabel>Numero:</FormLabel><Form.Control disabled value={legajo.numero} />
          </Col>
          <Col>
            <FormLabel>DNI:</FormLabel><Form.Control disabled value={legajo.dni} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormLabel>Nombre:</FormLabel><Form.Control disabled value={legajo.nombre} />
          </Col>
          <Col>
            <FormLabel>Apellido:</FormLabel><Form.Control disabled value={legajo.apellido} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormLabel>Dirección:</FormLabel><Form.Control disabled value={legajo.direccion} />
          </Col>
          <Col>
            <FormLabel>Telefono:</FormLabel><Form.Control disabled value={legajo.numeroTelefono} />
          </Col>
        </Row>
      </div> : <div>
        <Form.Control type="text"
          placeholder="número de legajo"
          value={inputValue}
          onKeyPress={checkKeyPress}
          onChange={e => setInputValue(e.target.value)} />
      </div>
      }
    </Container>
  )
}

export default LegajoSelector;