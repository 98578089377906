import React from "react";
import { UIContext } from "../../contexts/uiContext";
import ConceptoLiquidacionService from "../../services/concepto-liquidacion-service";
import BaseDarkSelect from "./base-dark-select";

function ConceptoLiquidacionSelector(props) {
  const [conceptosLiquidacionOptions, setConceptosLiquidacionOptions] = React.useState([])
  const { showMessage } = React.useContext(UIContext);

  React.useEffect(() => {
    buscar()
  }, [props.tipoLiquidacion])

  function buscar() {
    const filters = {
      tipoLiquidacion: props.tipoLiquidacion
    }
    ConceptoLiquidacionService.getConceptosLiquidacion(1, 500, filters)
      .then(page => {
        setConceptosLiquidacionOptions(page.content.sort((a, b) => a.codigo - b.codigo).map(conceptoLiquidacion => ({ value: conceptoLiquidacion, label: `${conceptoLiquidacion.codigo} - ${conceptoLiquidacion.nombre}` })))
      })
      .catch((error) => {
        showMessage('Ocurrió un error al recuperar los conceptos')
      })
  }

  return (
    <BaseDarkSelect options={conceptosLiquidacionOptions}
      value={{ value: props.value, label: props.value ? `${props.value.codigo} - ${props.value.nombre}` : '' }}
      isClearable
      onChange={e => props.onSelect(e?.value ? e.value : null, e)} />
  )
}

export default ConceptoLiquidacionSelector;