import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import LegajoService from "../../../../services/legajo-service";
import { currencyFormat } from "../../../shared/numbers";

function DetalleLiquidacion(props) {

  const liquidacion = props.liquidacion;
  const [legajo, setLegajo] = React.useState(props.legajo ? props.legajo : null);

  React.useEffect(() => {
    const relacionLegajo = props.liquidacion.relaciones.find(eachRelacion => eachRelacion.type === "legajo")

    LegajoService.getLegajo(relacionLegajo.id).then(data => {
      setLegajo(data);
    })
  }, [])

  function currencyOrZero(num) {
    return currencyFormat(num || 0);
  }

  const variablesConceptoColumns = [
    { dataField: 'codigo', text: 'Código' },
    { dataField: 'nombre', text: 'Concepto' },
    {
      isDummyField: true,
      text: 'Porcentaje',
      formatter: (cell, row, rowIndex) => {
        return row.variableLiquidacion.find(variable => variable.nombre === 'PORCENTAJE_CONCEPTO_LIQUIDACION').valor
      },
    },
    {
      isDummyField: true,
      text: 'Cantidad',
      formatter: (cell, row, rowIndex) => {
        return row.variableLiquidacion.find(variable => variable.nombre === 'CANTIDAD_CONCEPTO_LIQUIDACION').valor
      },
    },
    {
      isDummyField: true,
      text: 'Importe',
      formatter: (cell, row, rowIndex) => {
        const valor = row.variableLiquidacion.find(variable => variable.nombre === 'IMPORTE_CONCEPTO_LIQUIDACION').valor;
        return currencyOrZero(valor);
      },
    },
  ]

  const codigosConceptosNoExpandibles = liquidacion.conceptosLiquidados
    .filter(concepto => concepto.variableLiquidacion?.length <= 3)
    .map(concepto => concepto.codigo);

  const variablesConceptoExpandRow = {
    renderer: row => {
      return <ul>
        {row.variableLiquidacion.filter(variable =>
          variable.nombre !== 'IMPORTE_CONCEPTO_LIQUIDACION' &&
          variable.nombre !== 'CANTIDAD_CONCEPTO_LIQUIDACION' &&
          variable.nombre !== 'PORCENTAJE_CONCEPTO_LIQUIDACION')
          .map(variable => <li><b>{variable.nombre}</b>: {variable.valor} </li>)
        }
      </ul>
    },
    showExpandColumn: true,
    nonExpandable: codigosConceptosNoExpandibles
  };

  return (
    <div>
      <Card className="central-div">
        <Tabs>
          <TabList>
            <Tab>Detalle liquidacion</Tab>
            <Tab>Variables liquidacion</Tab>
          </TabList>

          <TabPanel>
            <Row>
              <Col>
                {legajo && <div >
                  <span>Legajo: </span>{legajo.numero}<br />
                  <span>Nombre y apellido: </span>{legajo.nombre} {legajo.apellido}<br />
                  <span>Cuil: </span>{legajo.cuil}<br />
                  <span>Fecha Ingreso: </span>{legajo.fechaIngreso.toLocaleDateString()}<br />
                </div>}
              </Col>
              <Col>
                <div>
                  <span>Categoria: </span>{liquidacion?.relaciones?.find(relacion => relacion.type === "categoria").metadata.nombre}<br />
                  <span>Período pago: </span>{liquidacion?.relaciones?.find(relacion => relacion.type === "caratula").metadata.periodoPago}<br />
                </div>
              </Col>
            </Row>
            <Row>
              <Table bordered size="sm">
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Denominación</th>
                    <th>Renumeración</th>
                    <th>Descuento</th>
                  </tr>
                </thead>
                <tbody>
                  {liquidacion && liquidacion.conceptosLiquidados && liquidacion.conceptosLiquidados.map((eachConceptoLiquidado, index) =>
                    <tr key={index}>
                      <td>{eachConceptoLiquidado.codigo}</td>
                      <td>{eachConceptoLiquidado.nombre}</td>
                      <td>{eachConceptoLiquidado.tipo === "HABER" && currencyFormat(eachConceptoLiquidado.valor)} </td>
                      <td>{eachConceptoLiquidado.tipo === "DESCUENTO" && currencyFormat(eachConceptoLiquidado.valor)}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <h5>Subtotales: {currencyFormat(liquidacion.subtotalHaberes)} y {currencyFormat(liquidacion.subtotalDescuentos)}</h5>
              <h5>Total neto: {liquidacion && currencyFormat(liquidacion.totalNeto)}</h5>
            </Row>
          </TabPanel>
          <TabPanel>
            <h3>Variables de liquidacion</h3>
            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th>Nombre de la variable</th>
                  <th>Valor de variable</th>
                </tr>
              </thead>
              <tbody>
                {liquidacion && liquidacion.variablesLiquidacion && liquidacion.variablesLiquidacion.map((eachVariableLiquidacion, index) =>
                  <tr key={index}>
                    <td>{eachVariableLiquidacion.nombre}</td>
                    <td>{eachVariableLiquidacion.valor === null ? eachVariableLiquidacion.valorString : eachVariableLiquidacion.valor}</td>
                  </tr>
                )}
              </tbody>
            </Table >
            <br />
            <h3>Variables por concepto</h3>
            <BootstrapTable keyField='codigo'
              hover
              data={liquidacion.conceptosLiquidados}
              columns={variablesConceptoColumns}
              expandRow={variablesConceptoExpandRow}
            />
          </TabPanel>
        </Tabs>

      </Card>
    </div >
  )
}

export default DetalleLiquidacion;