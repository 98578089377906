import React from 'react';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ClipLoader from "react-spinners/ClipLoader";

export const UIContext = React.createContext(null);

export const UIProvider = (props) => {

  const iframeRef = React.useRef(null);

  const [uiLoading, setUILoading] = React.useState(false)

  const printDataUrl = dataUrl => {
    iframeRef.current.src = dataUrl;
  }

  const showMessage = (message, type) => {
    if (type === 'success') {
      toast.success(message)
    } else if (type === 'error') {
      toast.error(message)
    } else {
      toast(message)
    }
  }

  return (
    <UIContext.Provider value={{ showMessage, printDataUrl, setUILoading }}>
      <>
        <Modal
          show={uiLoading}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body style={{ textAlign: 'center' }}>
            <ClipLoader color="white" size={100} />
          </Modal.Body>
        </Modal>
        {props.children}
      </>
      <ToastContainer newestOnTop />
      <iframe name="impresionReportFrame"
        style={{ width: '1px', "height": "1px", "position": "absolute", "left": "-9999px" }}
        title="title"
        ref={iframeRef}
        onLoad={() => {
          if (iframeRef.current.src) {
            window.frames['impresionReportFrame'].print();
          }
        }}
      >
      </iframe>
    </UIContext.Provider>
  )
}