import React from "react"
import { Button, Container, Modal, Row } from "react-bootstrap";
import { Comparator, customFilter, numberFilter, textFilter } from 'react-bootstrap-table2-filter';
import { EditionContext } from "../../../../contexts/editionContext";
import { UIContext } from "../../../../contexts/uiContext";
import LiquidacionService from "../../../../services/liquidacion-service";
import CaratulaSelector from "../../../shared/caratula-selector";
import AdminTable from "../../../shared/admin-table";
import DetalleLiquidacion from './detalleLiquidacion.js';
import ClasificadorLegajoSelector from "../../../shared/clasificador-legajo-selector";
import { BsPlusLg, BsFileEarmarkText, BsPrinter, BsTrash } from "react-icons/bs";
import Badge from 'react-bootstrap/Badge';

function LiquidacionList() {

  const { setEdicion, objetoEdicion } = React.useContext(EditionContext)
  const [liquidacionesPage, setLiquidacionesPage] = React.useState({ content: [] });
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [caratula, setCaratula] = React.useState(null);
  const [clasificadorLegajo, setClasificadorLegajo] = React.useState(null)
  const [detalleLiquidacion, setDetalleLiquidacion] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const { printDataUrl, setUILoading } = React.useContext(UIContext);
  const divRef = React.useRef(null);
  const [filters, setFilters] = React.useState({});
  const { showMessage } = React.useContext(UIContext)

  React.useEffect(() => {
    buscar()
  }, [page, pageSize, filters.caratulaId, filters['legajo.clasificadorLegajo'], filters['legajo.apellido'],
    filters['legajo.nombre'], filters['legajo.numero'], filters['legajo.dni']]);

  function buscar() {
    LiquidacionService.getLiquidaciones(page, pageSize, filters)
      .then(data => {
        setLiquidacionesPage(data)
      })
  }

  const areFiltersSelected = () => {
    return Object.keys(filters).length > 0;
  };

  function deleteLiquidacionesByParams() {
    LiquidacionService.deleteLiquidaciones(filters).then(data => {
      setShowDeleteModal(false);
      buscar()
      handleSucces('Liquidaciones eliminadas con éxito');
    });
  }

  function handleSucces(message) {
    showMessage(message, 'success');
  }

  const fieldMappings = {
    'legajo.nombre': 'Nombre',
    'legajo.dni': 'DNI',
    'legajo.apellido': 'Apellido',
    'legajo.numero': 'Número',
    'caratulaId': 'Carátula'
  };

  const columns = [
    { dataField: 'legajo.nombre', text: 'Nombre', filter: textFilter() },
    {
      dataField: 'legajo.apellido', text: 'Apellido',
      filter: textFilter({
        style: { display: 'inline-grid' },
      }),
    },
    {
      dataField: 'legajo.dni', text: 'DNI',
      filter: numberFilter({
        comparators: [Comparator.EQ],
        defaultValue: { comparator: Comparator.EQ },
        comparatorStyle: { display: 'none' },
      }),
    },
    {
      dataField: 'legajo.numero', text: 'Número', filter: numberFilter({
        comparators: [Comparator.EQ],
        defaultValue: { comparator: Comparator.EQ },
        comparatorStyle: { display: 'none' },
      })
    },
    { dataField: 'totalNeto', text: 'Total neto' },
    {
      dataField: 'caratula.nombre',
      text: 'Caratula',
      filter: customFilter(),
      filterRenderer: () =>
        <CaratulaSelector value={caratula}
          onSelect={(newCaratula) => {
            setCaratula(newCaratula)
            if (newCaratula) {
              setFilters(currFilter => { return { ...currFilter, caratulaId: newCaratula.id } })
            } else {
              setFilters(currFilter => {
                delete currFilter.caratulaId
                return currFilter;
              })
            }
          }} />
    },
    {
      dataField: 'legajo.clasificadorLegajo',
      text: 'Clasificador Legajo',
      filter: customFilter(),
      filterRenderer: () =>
        <ClasificadorLegajoSelector value={filters.clasificadorLegajo}
          onSelect={(newClasificadorLegajo) => setFilters(currFilter => { return { ...currFilter, clasificadorLegajo: newClasificadorLegajo } })}
        />
    },
    {
      isDummyField: true,
      text: 'Detalle',
      width: '20px',
      formatter: (cell, row, rowIndex) => {
        return <>
          <Button onClick={() => detalle(row)} size="sm"
            title="Detalle">
            <BsFileEarmarkText />
          </Button>
        </>
      },
    },
    {
      isDummyField: true,
      text: 'Imprimir',
      width: '20px',
      formatter: (cell, row, rowIndex) => {
        return <>
          <Button onClick={() => imprimirLiquidacion(row.id)} size="sm"
            title="Imprimir">
            <BsPrinter />
          </Button>
        </>
      },
    },
  ];

  function detalle(liquidacion) {
    const detalle = Object.assign({}, liquidacion);
    setDetalleLiquidacion(detalle);
    setShowModal(true);
  }

  function imprimirLiquidacion(id) {
    setUILoading(true);
    LiquidacionService.getReporteLiquidacion(id)
      .then(dataUrl => printDataUrl(dataUrl))
      .finally(() => setUILoading(false));
  }

  function imprimirTodasLiquidaciones() {
    setUILoading(true);
    LiquidacionService.imprimirLiquidaciones(caratula.id, clasificadorLegajo)
      .then(dataUrl => printDataUrl(dataUrl))
      .finally(() => setUILoading(false));
  }

  function onTableChange(type, newState) {
    if (type === 'filter') {
      setFilters(() => {
        const newFilter = {};
        for (const filterName in newState.filters) {
          const filterObject = newState.filters[filterName];
          let value;

          if (filterObject.filterType === 'NUMBER') {
            value = filterObject.filterVal.number;
          } else if (filterObject.filterType === 'TEXT') {
            value = filterObject.filterVal.trim();
          } else {
            value = filterObject.filterVal;
          }

          if (value) {
            newFilter[filterName] = value;
          }
        }
        return newFilter;
      });
    }
    return null;
  }


  function filterValue(key) {
    if (key === 'caratulaId') {
      return caratula.descripcion;
    }
    return filters[key]
  }

  return (
    <div ref={divRef}>
      <Container className="central-div">
        <div className="title-and-buttons">
          <h1 className="title">Liquidaciones</h1>
          <span className="buttons">
            <Button onClick={() => { setEdicion(true) }}
              title="Nueva Liquidación"
            >
              <BsPlusLg />
            </Button>
            <Button onClick={() => imprimirTodasLiquidaciones()}
              title="Imprimir todos"
              disabled={!caratula}
            >
              <BsPrinter />
            </Button>
            <Button onClick={() => { setShowDeleteModal(true) }}
              title="Borrar Liquidacion"
              variant="danger"
            >
              <BsTrash />
            </Button>
          </span>
        </div>
      </Container>
      <Container className="central-div">
        <Row>
          <AdminTable
            data={liquidacionesPage.content}
            columns={columns}
            totalSize={liquidacionesPage.totalElements}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            onTableChange={onTableChange}
          />
        </Row>
      </Container>
      {detalleLiquidacion && detalleLiquidacion.conceptosLiquidados &&
        <Modal size="lg" show={showModal}
          container={divRef.current}
          onHide={() => setShowModal(false)}
          animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Detalle de Liquidación</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }} >
            <DetalleLiquidacion liquidacion={detalleLiquidacion} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>}

      <Modal size="lg" show={showDeleteModal}
        container={divRef.current}
        onHide={() => setShowDeleteModal(false)}
        animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Borrar Liquidaciones</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }} >
          <h4>
            Está por borrar <span style={{ fontSize: '35px', color: 'red' }}>{liquidacionesPage.totalElements}</span> liquidaciones coincidentes con los siguientes parámetros:
          </h4>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            {Object.keys(filters).map((key, index) => (
              <Badge key={index} bg="black" style={{ fontSize: '1.3em', marginRight: '3px', border: '1px solid white' }} pill>
                {fieldMappings[key]}: {filterValue(key)}
              </Badge>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowDeleteModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" disabled={!areFiltersSelected()} onClick={() => deleteLiquidacionesByParams()}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default LiquidacionList;