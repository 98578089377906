import React from "react";
import { Button, Col, Container, Form, FormLabel, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { EditionContext } from "../../../../contexts/editionContext";
import { UIContext } from "../../../../contexts/uiContext";
import LiquidacionService from "../../../../services/liquidacion-service";
import CaratulaSelector from "../../../shared/caratula-selector";

const emptyForm = {}

function LiquidacionNew() {

  const { setEdicion, objetoEdicion } = React.useContext(EditionContext)
  const [showModal, setShowModal] = React.useState(false)
  const [form, setForm] = React.useState(objetoEdicion ? objetoEdicion : emptyForm)
  const { showMessage, setUILoading } = React.useContext(UIContext)
  const [validated, setValidated] = React.useState(false);
  const formRef = React.useRef(null);
  const mainDivRef = React.useRef(null);

  const [caratula, setCaratula] = React.useState(null);

  const handleSuccess = message => {
    showMessage(message, 'success');
    setEdicion(false);
  }

  const clearForm = () => {
    setForm(emptyForm)
  }

  const submit = () => {
    setShowModal(false);
    setUILoading(true);
    const body = Object.assign({}, form)
    body.caratula = caratula;

    if (form.id) {
      LiquidacionService.updateLiquidacion(body).then(liquidacion => {
        handleSuccess('Liquidacion actualizado')
      }).catch(error => {
        showMessage(error.response.data.error, 'error')
      }).finally(() => setUILoading(false));
    } else {
      LiquidacionService.createLiquidacion(body).then(data => {
        handleSuccess('Nueva liquidacion generada.')
        clearForm()
      }).catch(error => {
        showMessage(error.response.data.error, 'error')
      }).finally(() => setUILoading(false));
    }
  }

  function confirmar() {
    const form = formRef.current
    if (form.checkValidity()) {
      setShowModal(true)
    }
    setValidated(true)
  }

  return (
    <div ref={mainDivRef}>
      <Container className="central-div">
        <div className="title-and-buttons">
          <h1 className="title">Generar liquidación</h1>
        </div>
      </Container>
      <Container className="central-div">
        <Form noValidate validated={validated}
          ref={formRef}
          className="central-div"
          autoComplete="off">
          <Row>
            <Form.Group controlId="caratula">
              <FormLabel>Carátula</FormLabel>
              <CaratulaSelector
                value={caratula}
                onSelect={(newCaratula) => setCaratula(newCaratula)} />
            </Form.Group>
          </Row>
        </Form>
        <Row className="row-mb-5">
          <Col>
            <div className="central-div">
              <Link to={"/"}>
                <Button onClick={() => setEdicion(false)} size="lg" variant="danger" className="button-mr-5">
                  Cancelar
                </Button>
              </Link>
              <Button size="lg" type="submit" onClick={() => { confirmar() }} disabled={caratula === null}>
                Confirmar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={showModal} onHide={() => setShowModal(false)} animation={false} container={mainDivRef.current}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>Generar liquidacion</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={submit}>
            Guardar liquidacion
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default LiquidacionNew;