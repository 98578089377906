import React from 'react'
import { Card, Col, Container, Form, FormGroup, FormLabel } from 'react-bootstrap'
import Row from "react-bootstrap/Row";
import LegajoService from '../../../../services/legajo-service';
import { UIContext } from '../../../../contexts/uiContext'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { Link } from "react-router-dom";
import { EditionContext } from '../../../../contexts/editionContext'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LicenciaTab from './licenciaTab';
import FamiliarTab from './familiaresTab';
import InasistenciaTab from './InasistenciaTab';
import CategoriaTab from './categoriaTab';
import moment from 'moment';
import AreaSelector from '../../../shared/area-selector';
import { LegajoProvider } from '../../../../contexts/legajoContext';
import ConceptoFijoTab from './conceptoFijoTab';
import { BiHide, BiShow } from "react-icons/bi";
import '../../../../index.css'
import ClasificadorLegajoSelector from '../../../shared/clasificador-legajo-selector';
import InputMask from 'react-input-mask';
import UtilsService from '../../../../services/utils-service';
import DatePicker from '../../../shared/date-picker';

const emptyForm = {
  numero: '',
  nombre: '',
  apellido: '',
  direccion: '',
  numeroTelefono: '',
  areaTrabajo: '',
  licencias: [],
  categorias: [],
  familiares: [],
  inasistencias: [],
  valoresConceptoLiquidacion: [],
};

const emptyFamiliar = [{
  nombre: '',
  apellido: '',
  dni: '',
  sexo: '',
  direccion: '',
  parentesco: '',
  fechaNacimiento: new Date(),
  fechaVinculo: new Date()
}];

function LegajoNew() {

  const { setEdicion, objetoEdicion } = React.useContext(EditionContext)
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false)
  const [form, setForm] = React.useState(objetoEdicion ? objetoEdicion : emptyForm)
  const { showMessage } = React.useContext(UIContext)
  const mainDivRef = React.useRef(null);
  const [mostrarClaveElectronica, setMostrarClaveElectronica] = React.useState(false);

  const handleSuccess = message => {
    setShowConfirmationModal(false);
    showMessage(message, 'success');
    setEdicion(false);
  }

  const clearForm = () => {
    setForm(emptyForm)
  }

  function validateForm(thisForm) {
    const errors = [];

    if (!thisForm.numero || isNaN(thisForm.numero)) {
      errors.push('Debe completar el número de legajo.');
    }

    if (!thisForm.nombre || thisForm.nombre.trim() === '') {
      errors.push('Debe completar el nombre de la persona.');
    }

    if (!thisForm.apellido || thisForm.apellido.trim() === '') {
      errors.push('Debe completar el apellido de la persona.');
    }

    if (!thisForm.dni || isNaN(thisForm.dni)) {
      errors.push('Debe completar el DNI.');
    }

    if (!thisForm.cuil || thisForm.cuil.trim() === '') {
      errors.push('Debe completar el CUIL.');
    }

    if (!thisForm.clasificadorLegajo || thisForm.clasificadorLegajo.trim() === '') {
      errors.push('Debe seleccionar un clasificador de legajo.');
    }

    if (isNaN(thisForm.fechaIngreso?.getTime())) {
      errors.push('Debe seleccionar Fecha de Ingreso.');
    }

    for (let i = 0; i < thisForm.licencias.length; i++) {
      if (!thisForm.licencias[i].tipoLicencia || thisForm.licencias[i].tipoLicencia.trim() === '') {
        errors.push('Debe completar el tipo de licencia')
      }
      if (!thisForm.licencias[i].fechaDesde || isNaN(thisForm.licencias[i].fechaDesde.getTime())) {
        errors.push('Debe completar fecha desde en licencia');
      }
      if (!thisForm.licencias[i].fechaHasta || isNaN(thisForm.licencias[i].fechaHasta.getTime())) {
        errors.push('Debe completar fecha de finalización en licencia');
      }
    }

    for (let i = 0; i < thisForm.inasistencias.length; i++) {
      if (!thisForm.inasistencias[i].tipoInasistencia || thisForm.inasistencias[i].tipoInasistencia.trim() === '') {
        errors.push('Debe completar el tipo de inasistencia')
      }
      if (!thisForm.inasistencias[i].fechaInasistencia || isNaN(thisForm.inasistencias[i].fechaInasistencia.getTime())) {
        errors.push('Debe completar la fecha de inasistencia')
      }
    }

    for (let i = 0; i < thisForm.categorias.length; i++) {
      if (!thisForm.categorias[i].categoria) {
        errors.push(`Debe seleccionar una Categoría en la fila ${i + 1} de la pestaña Categorías`);
      }

      if (!thisForm.categorias[i].fechaDesde || isNaN(thisForm.categorias[i].fechaDesde.getTime())) {
        errors.push(`Debe completar la Fecha Desde en en la fila ${i + 1} de la pestaña Categorías`);
      }
    }

    for (let i = 0; i < thisForm.familiares.length; i++) {
      if (!thisForm.familiares[i].nombre || thisForm.familiares[i].nombre.trim() === '') {
        errors.push('Debe completar los nombres de todos los familiares.');
      }

      if (!thisForm.familiares[i].fechaNacimiento || isNaN(thisForm.familiares[i].fechaNacimiento.getTime())) {
        errors.push('Debe completar la fecha de nacimiento de todos los familiares');
      }

      if (!thisForm.familiares[i].apellido || thisForm.familiares[i].apellido.trim() === '') {
        errors.push('Debe completar el apellido de todos los familiares.');
      }

      if (!thisForm.familiares[i].dni || isNaN(thisForm.familiares[i].dni)) {
        errors.push('Debe completar el DNI de todos los familiares.');
      }

      if (!thisForm.familiares[i].parentesco || thisForm.familiares[i].parentesco.trim() === '') {
        errors.push('Debe completar el parentesco de todos los familiares')
      }
    }
    return errors.length > 0 ? errors : null;
  }


  const submit = () => {

    const body = JSON.parse(JSON.stringify(form))

    body.categorias = []
    if (form.categorias) {
      form.categorias.forEach(eachCategoriaLegajo => {
        body.categorias.push({
          categoriaId: eachCategoriaLegajo.categoria.id,
          fechaDesde: eachCategoriaLegajo.fechaDesde,
          fechaHasta: eachCategoriaLegajo.fechaHasta,
          id: eachCategoriaLegajo.id
        })
      });
    }

    body.valoresConceptoLiquidacion = [...form.valoresConceptoLiquidacion]
      .map(valores => ({
        conceptoLiquidacionId: valores.conceptoLiquidacion.id,
        importe: valores.importe,
        cantidad: valores.cantidad,
        porcentaje: valores.porcentaje,
        id: valores.id,
      }))

    if (body.id) {
      LegajoService.updateLegajo(body).then((legajo) => {
        handleSuccess('Legajo actualizado');
      }).catch(error => {
        showMessage(error.response.data.error, 'error')
      });
    } else {
      LegajoService.createLegajo(body).then((data) => {
        handleSuccess('Nuevo legajo creado.');
        clearForm();
      }).catch(error => {
        showMessage(error.response.data.error, 'error')
      });
    }

  };

  const handleFormValue = (name, value) => {
    const newForm = {
      ...form,
      [name]: value
    }
    setForm(newForm)
  }

  function confirmar() {
    const validationError = validateForm(form);

    if (validationError) {
      validationError.forEach(error => showMessage(error, 'error'))
      return;
    }
    setShowConfirmationModal(true)
  }

  function returnCategoriaNombre() {
    if (form.categorias && form.categorias[0] && form.categorias[0].categoria) {
      return form.categorias[0].categoria.nombre
    }
    return "No tiene categoría asignada"
  }

  function getTiempoEnCategoriaEnTexto() {
    if (!form.categorias || !form.categorias[0] || !form.categorias[0].fechaDesde) {
      return "No tiene."
    }
    return tiempoHastaHoyEnTexto(form.categorias[0].fechaDesde)
  }

  function getAntiguedadEnTexto() {
    if (!form.fechaIngreso) {
      return "No tiene"
    }
    return tiempoHastaHoyEnTexto(form.fechaIngreso)
  }

  function tiempoHastaHoyEnTexto(fecha) {
    const hoy = moment()
    const diaInicio = moment(fecha);
    let label = "tiene "

    const diffA = hoy.diff(diaInicio, 'years')
    if (diffA >= 1) {
      hoy.subtract(diffA, "years")
      if (diffA > 1) {
        label += diffA + " años "
      } else {
        label += diffA + " año "
      }
    }
    const diffM = hoy.diff(diaInicio, 'months')
    if (diffM >= 1) {
      hoy.subtract(diffM, "months")
      if (diffM > 1) {
        label += diffM + " meses "
      } else {
        label += diffM + " mes "
      }
    }
    const diffD = hoy.diff(diaInicio, 'days')
    if (diffD >= 1) {
      if (diffD > 1) {
        label += diffD + " días"
      } else {
        label += diffD + " día"
      }
    }
    return label

  }

  const nivelesEducacion = [
    { value: '', label: '' },
    { value: 'PRIMARIA', label: 'Primaria' },
    { value: 'SECUNDARIA', label: 'Secundaria' },
    { value: 'UNIVERSITARIA_SUPERIOR', label: 'Universitaria o superior' }
  ]

  const handleCuilChange = (name, value) => {
    const numericValue = value.replace(/\D/g, '');
    handleFormValue(name, numericValue);
  };

  return (
    <LegajoProvider legajo={form} setLegajo={setForm}>
      <div ref={mainDivRef}>
        <Container className="central-div">
          <Tabs>
            <TabList>
              <Tab>Legajo</Tab>
              <Tab>Categorías</Tab>
              <Tab>Conceptos Fijos</Tab>
              <Tab>Inasistencias/Llegadas tardes</Tab>
              <Tab>Licencias</Tab>
              <Tab>Familiares</Tab>
            </TabList>

            <TabPanel>
              <Form
                className="central-div"
                autoComplete="new-password"
              >
                <h2>Legajo</h2>
                <Row>
                  <Col>
                    <Form.Group controlId="numero">
                      <FormLabel>Número</FormLabel>
                      <Form.Control type="text" value={form.numero} required
                        autoComplete="new-password"
                        onChange={e => handleFormValue('numero', UtilsService.numbersOnly(e.target.value))} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="nombre">
                      <FormLabel>Nombre</FormLabel>
                      <Form.Control type="text" value={form.nombre} required
                        autoComplete="new-password"
                        onChange={e => handleFormValue('nombre', UtilsService.lettersOnly(e.target.value))} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="apellido">
                      <FormLabel>Apellido</FormLabel>
                      <Form.Control type="text" value={form.apellido} required
                        autoComplete="new-password"
                        onChange={e => handleFormValue('apellido', UtilsService.lettersOnly(e.target.value))} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="dni">
                      <FormLabel>DNI</FormLabel>
                      <Form.Control type="text" value={form.dni} required
                        autoComplete="new-password"
                        onChange={e => handleFormValue('dni', UtilsService.numbersOnly(e.target.value))} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="cuil">
                      <FormLabel>Cuil</FormLabel>
                      <InputMask
                        mask="99-99999999-9"
                        maskChar=""
                        onChange={e => handleCuilChange('cuil', e.target.value)}
                        value={form.cuil} required
                        className='form-control'
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <div className="password">
                      <Form.Group controlId="claveElectronica">
                        <FormLabel>Clave electrónica</FormLabel>
                        <Form.Control type={mostrarClaveElectronica === true ? "text" : "password"}
                          value={form.claveElectronica}
                          onChange={e => handleFormValue('claveElectronica', e.target.value)} />
                      </Form.Group>
                      <Button onClick={() => setMostrarClaveElectronica(!mostrarClaveElectronica)}>
                        {mostrarClaveElectronica ? <BiHide /> : <BiShow />}
                      </Button>
                    </div>
                  </Col>
                  <Col>
                    <Form.Group controlId="direccion">
                      <FormLabel>Dirección</FormLabel>
                      <Form.Control type="text" value={form.direccion} required
                        autoComplete="new-password"
                        onChange={e => handleFormValue('direccion', e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="numeroTelefono">
                      <FormLabel>Teléfono</FormLabel>
                      <Form.Control type="text" value={form.numeroTelefono} required
                        autoComplete="new-password"
                        onChange={e => handleFormValue('numeroTelefono', UtilsService.numbersOnly(e.target.value))} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="areaTrabajo">
                      <FormLabel>Area de trabajo</FormLabel>
                      <AreaSelector value={form.areaTrabajo}
                        onSelect={area => {
                          handleFormValue('areaTrabajo', area.value)
                        }} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="clasificadorLegajo">
                      <FormLabel>Clasificador Legajo</FormLabel>
                      <ClasificadorLegajoSelector value={form.clasificadorLegajo} required
                        onSelect={value => handleFormValue('clasificadorLegajo', value)} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <FormGroup >
                      <FormLabel>Fecha Ingreso</FormLabel>
                      <DatePicker selected={form.fechaIngreso} onChange={date => handleFormValue('fechaIngreso', date)} />
                    </FormGroup>
                  </Col>
                  <Col>
                    <Form.Group controlId="educacion">
                      <FormLabel>Educación</FormLabel>
                      <Form.Select
                        className='select-dark'
                        value={form.educacion}
                        onChange={e => handleFormValue('educacion', e.target.value)}>
                        {nivelesEducacion.map(option =>
                          <option value={option.value}>{option.label}</option>
                        )}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Card className='color-fondo'>
                  <h3>Categoria: {returnCategoriaNombre()}</h3>
                  <h4>Tiempo en categoria: {getTiempoEnCategoriaEnTexto()}</h4>
                  <h4>Antiguedad: {getAntiguedadEnTexto()}</h4>
                </Card>
              </Form>
            </TabPanel>
            <TabPanel>
              <CategoriaTab />
            </TabPanel>
            <TabPanel>
              <ConceptoFijoTab />
            </TabPanel>
            <TabPanel>
              <InasistenciaTab />
            </TabPanel>
            <TabPanel>
              <LicenciaTab />
            </TabPanel>
            <TabPanel>
              <FamiliarTab />
            </TabPanel>
          </Tabs>
          <Row className="row-mb-5">
            <Col>
              <div className="central-div">
                <Link to={"/"}>
                  <Button onClick={() => setEdicion(false)} size="lg" variant="danger" className="button-mr-5">
                    Cancelar
                  </Button>
                </Link>
                <Button size="lg" type="submit" onClick={() => { confirmar() }} >
                  Confirmar
                </Button>
              </div>
            </Col>
          </Row>
        </Container>

        <Modal show={showConfirmationModal}
          container={mainDivRef.current}
          onHide={() => setShowConfirmationModal(false)}
          animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Seguro que quieres agregar o cambiar legajo?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={submit}>
              Guardar legajo
            </Button>
          </Modal.Footer>
        </Modal>
      </div >
    </LegajoProvider >

  )
}

export default LegajoNew;