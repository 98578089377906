import axios from 'axios';


function fromServer(caratula) {
  if (caratula.fechaPago) {
    caratula.fechaPago = new Date(caratula.fechaPago)
  }
  caratula.periodoPago = new Date(`${caratula.anioPago}-${caratula.mesPago.toString().padStart(2, '0')}-01T12:00`);
  return caratula
}

const CaratulaService = {
  getCaratulas: (page, size) => {
    const params = {
      page: page ? page - 1 : 0,
      size: size ? size : 10
    }
    return axios.get('/api/liquisueldos/caratula', { params }).then(resp => {
      resp.data.content.forEach(fromServer)
      return resp.data
    })
  },
  getCaratula: id => {
    return axios.get('/api/liquisueldos/caratula/' + id).then(resp => fromServer(resp.data));
  },
  getCaratulaVigente: () => {
    return axios.get('/api/liquisueldos/caratula/vigente').then(resp => fromServer(resp.data));
  },
  createCaratula: caratula => {
    return axios.post('/api/liquisueldos/caratula', caratula).then(resp => resp.data);
  },
  updateCaratula: caratula => {
    return axios.put('/api/liquisueldos/caratula', caratula).then(resp => resp.data);
  }
}

export default CaratulaService;