import React from 'react'
import { Card, Col, Container, Form, FormLabel } from 'react-bootstrap'
import Row from "react-bootstrap/Row";
import ConceptoLiquidacionService from '../../../../services/concepto-liquidacion-service';
import { UIContext } from '../../../../contexts/uiContext'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { Link, useParams } from "react-router-dom";
import { EditionContext } from '../../../../contexts/editionContext'
import SiNo from '../../../shared/si-no';
import { NumericFormat } from 'react-number-format';
import GeneradorArchivoService from '../../../../services/generador-archivo-service';

const emptyForm = {
  nombre: ''
}

function GeneradorArchivoNew() {

  const { setEdicion, objetoEdicion } = React.useContext(EditionContext)
  const [form, setForm] = React.useState(objetoEdicion ? objetoEdicion : emptyForm)
  const [showModal, setShowModal] = React.useState(false)
  const { showMessage } = React.useContext(UIContext)
  const [validated, setValidated] = React.useState(false);
  const formRef = React.useRef(null);
  const mainDivRef = React.useRef(null);

  const handleSuccess = message => {
    setShowModal(false);
    showMessage(message, 'success');
    setEdicion(false);
  }

  const submit = () => {
    const body = Object.assign({}, form)
    if (form.id) {
      GeneradorArchivoService.updateGeneradorArchivo(body).then(generadorArchivo => {
        handleSuccess('Generador de archivos actualizado')
      })
    } else {
      GeneradorArchivoService.createGeneradorArchivo(body).then(data => {
        handleSuccess('Nuevo generador de archivos creado')
        clearForm()
      })
    }
  }

  const handleFormValue = (name, value) => {
    const newForm = {
      ...form,
      [name]: value
    }
    setForm(newForm)
  }

  function confirmar() {
    const form = formRef.current
    if (form.checkValidity()) {
      setShowModal(true)
    }
    setValidated(true)
  }

  const clearForm = () => {
    setForm(emptyForm)
  }

  return (
    <div ref={mainDivRef}>
      <Container className="central-div">
        <div className="title-and-buttons">
          <h1 className="title">Crear nuevo generador de archivos.</h1>
        </div>
      </Container>
      <Container className="central-div">
        <Form noValidate validated={validated} ref={formRef}
          className="central-div"
          autoComplete="off" >
          <Row>
            <Col >
              <Form.Group>
                <FormLabel>Nombre</FormLabel>
                <Form.Control type="text" name="nombre" value={form.nombre} required
                  onChange={e => handleFormValue('nombre', e.target.value)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="filtroCaratula">
                <FormLabel htmlFor="filtroCaratula">Filtro Caratula</FormLabel>
                <SiNo id="filtroCaratula" dropdown={true} value={form.filtroCaratula}
                  onSelect={value => handleFormValue('filtroCaratula', value)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="filtroClasificadorLegajo">
                <FormLabel htmlFor="filtroClasificadorLegajo">Filtro Clasificador Legajo</FormLabel>
                <SiNo id="filtroClasificadorLegajo" dropdown={true} value={form.filtroClasificadorLegajo}
                  onSelect={value => handleFormValue('filtroClasificadorLegajo', value)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="filtroNovedad">
                <FormLabel htmlFor="filtroNovedad">Filtro Novedad</FormLabel>
                <SiNo id="filtroNovedad" dropdown={true} value={form.filtroNovedad}
                  onSelect={value => handleFormValue('filtroNovedad', value)} />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <Row className="row-mb-5">
          <Col>
            <div className="central-div">
              <Link to={"/"}>
                <Button onClick={() => setEdicion(false)} size="lg" variant="danger" className="button-mr-5">
                  Cancelar
                </Button>
              </Link>
              <Button size="lg" type="submit" onClick={() => { confirmar() }} >
                Confirmar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={() => setShowModal(false)} animation={false} container={mainDivRef.current}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Seguro que quieres agregar o cambiar este generador?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={submit}>
            Guardar generador de archivo
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default GeneradorArchivoNew;