import axios from 'axios';


function legajoFromServer(legajo) {

  legajo.fechaIngreso = new Date(legajo.fechaIngreso)

  if (legajo.familiares) {
    legajo.familiares.forEach(familiar => {
      familiar.fechaNacimiento = new Date(familiar.fechaNacimiento)
      if (familiar.fechaInicioVinculo) {
        familiar.fechaInicioVinculo = new Date(familiar.fechaInicioVinculo)
      }
    })
  }

  if (legajo.inasistencias) {
    legajo.inasistencias.forEach(inasistencia => {
      if (inasistencia.fechaInasistencia) {
        inasistencia.fechaInasistencia = new Date(inasistencia.fechaInasistencia)
      }
    })
  }

  if (legajo.licencias) {
    legajo.licencias.forEach(licencia => {
      if (licencia.fechaDesde) {
        licencia.fechaDesde = new Date(licencia.fechaDesde)
      }
      if (licencia.fechaHasta) {
        licencia.fechaHasta = new Date(licencia.fechaHasta)
      }
    })
  }

  if (legajo.valoresConceptoLiquidacion === null) {
    legajo.valoresConceptoLiquidacion = [];
  }

  return legajo
}

function categoriaFromServer(categoriaLegajo) {
  if (categoriaLegajo.fechaDesde) {
    categoriaLegajo.fechaDesde = new Date(categoriaLegajo.fechaDesde)
  }
}

const LegajoService = {

  getLegajos: (page, size, filters) => {
    const params = {
      page: page ? page - 1 : 0,
      size: size ? size : 10,
      activo: true,
      ...filters
    }
    return axios.get('/api/liquisueldos/legajo', { params }).then(resp => {
      resp.data.content.forEach(legajoFromServer);
      return resp.data
    })
  },
  getLegajo: id => {
    return axios.get('/api/liquisueldos/legajo/' + id).then(resp => legajoFromServer(resp.data))
  },
  getCategoriasByLegajoId: legajoId => {
    return axios.get('/api/liquisueldos/legajo/' + legajoId + "/categorias").then(resp => {
      resp.data.content.forEach(categoriaFromServer);
      resp.data.content.sort((a, b) => b.fechaDesde - a.fechaDesde);
      return resp.data
    })
  },
  createLegajo: legajo => {
    return axios.post('/api/liquisueldos/legajo', legajo).then(resp => resp.data)
  },
  updateLegajo: legajo => {
    return axios.put('/api/liquisueldos/legajo', legajo).then(resp => resp.data)
  },
  getPersonaPorNumero: numero => {
    return axios.get('/api/liquisueldos/legajo/by-numero/' + numero).then(resp => legajoFromServer(resp.data))
  },
  deactivateLegajo: (legajoId, formBaja) => {
    return axios.put('/api/liquisueldos/legajo/' + legajoId + "/deactivate", formBaja).then(resp => resp.data)
  }

}

export default LegajoService;