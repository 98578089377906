import React from "react";
import GeneradorArchivoService from "../../../../services/generador-archivo-service";
import { Button, Container, Modal, Row } from 'react-bootstrap';
import AdminTable from '../../../shared/admin-table';
import { EditionContext } from '../../../../contexts/editionContext';
import { BsPencil, BsPlusLg } from "react-icons/bs";
import { UIContext } from '../../../../contexts/uiContext'
import CaratulaSelector from "../../../shared/caratula-selector";
import ClasificadorLegajoSelector from "../../../shared/clasificador-legajo-selector";
import NovedadesSelector from "../../../shared/novedad-selector";

function GeneradorArchivoList() {
  const { setEdicion, setObjetoEdicion } = React.useContext(EditionContext)
  const [form, setForm] = React.useState({})
  const [archivoAGenerar, setArchivoAGenerar] = React.useState({})
  const [page, setPage] = React.useState(1);
  const { showMessage } = React.useContext(UIContext)
  const [pageSize, setPageSize] = React.useState(10);
  const [generadorArchivoPage, setGeneradorArchivoPage] = React.useState({ content: [] });
  const [showModalEjecutar, setShowModalEjecutar] = React.useState(false)
  const formRef = React.useRef(null);
  const mainDivRef = React.useRef(null);

  React.useEffect(() => {
    GeneradorArchivoService.getGeneradorArchivos(page, pageSize).then(data => {
      setGeneradorArchivoPage(data)
    })
  }, [page, pageSize])

  const columns = [
    { dataField: 'nombre', text: 'Nombre' },
    {
      isDummyField: true,
      text: 'Modificar',
      formatter: (cell, row, rowIndex) => {
        return <Button onClick={() => {
          const objeto = Object.assign({}, row);
          setObjetoEdicion(objeto);
          setEdicion(true)
        }}
          title='Modificar'
          size='sm'
        >
          <BsPencil />
        </Button>
      }
    },
    {
      isDummyField: true,
      text: 'Ejecutar',
      formatter: (cell, row, rowIndex) => {
        return <Button onClick={() => {
          const objeto = Object.assign({}, row);
          setArchivoAGenerar(objeto)
          setShowModalEjecutar(true)
        }}
          title='Ejecutar'
          size='sm'
        >
          <BsPencil />
        </Button>
      }
    }
  ];

  const handleFormValue = (name, value) => {
    const newForm = {
      ...form,
      [name]: value
    }
    setForm(newForm)
  }

  const handleSuccess = message => {
    setShowModalEjecutar(false);
    showMessage(message, 'success');
    setEdicion(false);
  }

  function generarArchivoPlano() {
    const body = Object.assign({}, form)
    GeneradorArchivoService.generarArchivo(form.caratula.id, form.clasificadorLegajo, form.novedad.id, archivoAGenerar.id).then(data => {
      handleSuccess('Generando archivo');
      setForm({})
    });
  }

  return (
    <div ref={mainDivRef}>
      <Container className="central-div">
        <div className="title-and-buttons">
          <h1 className="title">Generador Archivos</h1>
          <span className="buttons">
            <Button onClick={() => {
              setEdicion(true);
              setObjetoEdicion(null)
            }} >
              <BsPlusLg />
            </Button>

          </span>
        </div>
      </Container>
      <Container className="central-div">
        <Row>
          <AdminTable data={generadorArchivoPage.content}
            columns={columns}
            totalSize={generadorArchivoPage.totalElements}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </Row>
      </Container>

      <Modal show={showModalEjecutar} onHide={() => setShowModalEjecutar(false)} animation={false} container={mainDivRef.current}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {archivoAGenerar.filtroCaratula && <div>
            <span>Caratula</span>
            <CaratulaSelector value={form.caratula} onSelect={(newCaratula) => {
              if (newCaratula) {
                handleFormValue('caratula', newCaratula)
              } else {
                handleFormValue('caratula', '')
              }
            }} />
          </div>
          }
          {archivoAGenerar.filtroClasificadorLegajo && <div>
            <span>Clasificador Legajo</span>
            <ClasificadorLegajoSelector value={form.clasificadorLegajo} onSelect={(newClasificadorLegajo) => {
              if (newClasificadorLegajo) {
                handleFormValue('clasificadorLegajo', newClasificadorLegajo)
              } else {
                handleFormValue('clasificadorLegajo', null)
              }
            }} />
          </div>
          }
          {archivoAGenerar.filtroNovedad && <div>
            <span>Novedad</span>
            <NovedadesSelector value={form.novedad} onSelect={(newNovedad) => {
              if (newNovedad) {
                handleFormValue('novedad', newNovedad)
              } else {
                handleFormValue('novedad', null)
              }
            }}
            />
          </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setForm({})
            setShowModalEjecutar(false)
          }}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={() => generarArchivoPlano()}>
            Generar archivo
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default GeneradorArchivoList;