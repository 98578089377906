import axios from 'axios';

function mapConceptosExcluyentes(conceptoLiquidacion) {
  if (!conceptoLiquidacion || !conceptoLiquidacion.conceptosExcluyentes) {
    return conceptoLiquidacion;
  }
  conceptoLiquidacion.conceptosExcluyentes = conceptoLiquidacion.conceptosExcluyentes.map(eachConceptoExcluyente => {
    return {
      codigo: eachConceptoExcluyente.relaciones[0].metadata.codigo,
      nombre: eachConceptoExcluyente.relaciones[0].metadata.nombre,
      id: eachConceptoExcluyente.relaciones[0].id,
    };
  });
  return conceptoLiquidacion;
}

const ConceptoLiquidacionService = {

  getConceptosLiquidacion: (page, size, filters) => {
    const params = {
      page: page ? page - 1 : 0,
      size: size ? size : 10,
      sortBy: "codigo",
      ...filters
    }
    return axios.get('/api/liquisueldos/concepto-liquidacion', { params }).then(resp => {
      resp.data.content.forEach(mapConceptosExcluyentes);
      return resp.data
    })
  },
  getConceptoLiquidacion: id => {
    return axios.get('/api/liquisueldos/concepto-liquidacion/' + id).then(resp => mapConceptosExcluyentes(resp.data))
  },
  createConceptoLiquidacion: conceptoLiquidacion => {
    return axios.post('/api/liquisueldos/concepto-liquidacion', conceptoLiquidacion).then(resp => resp.data)
  },
  updateConceptoLiquidacion: conceptoLiquidacion => {
    return axios.put('/api/liquisueldos/concepto-liquidacion', conceptoLiquidacion).then(resp => resp.data)
  }


}

export default ConceptoLiquidacionService;