import React from 'react';
import { EditionContext } from '../../../contexts/editionContext';
import CaratulaList from './list';
import CaratulaNew from './new';


function Caratula() {

  const { edicion } = React.useContext(EditionContext);

  return (

    edicion === true ?
      <CaratulaNew /> : <CaratulaList />
  )
}

export default Caratula;