import React from 'react';
import { Button, Table } from 'react-bootstrap'
import { BsPlusLg, BsTrash } from 'react-icons/bs';
import { LegajoContext } from '../../../../contexts/legajoContext';
import CategoriaSelector from '../../../shared/categoria-selector';
import DatePicker from '../../../shared/date-picker';

const emptyCategoria = {
  categoria: '',
  fechaDesde: null,
}

function CategoriaTab() {

  const { legajo, setLegajo } = React.useContext(LegajoContext);
  const formRef = React.useRef(null);
  const [validated, setValidated] = React.useState(false);

  function setCategorias(categorias) {
    const newLegajo = {
      ...legajo,
      categorias: categorias
    }
    setLegajo(newLegajo);
  }

  function addCategoria() {
    const newCategorias = [...legajo.categorias]
    newCategorias.unshift(Object.assign({}, emptyCategoria))
    setCategorias(newCategorias);
  }

  function removeCategoria(index) {
    const newCategorias = [...legajo.categorias]
    newCategorias.splice(index, 1)
    setCategorias(newCategorias)
  }

  function handleCategoriasValue(propiedad, nuevoValor, index) {
    const newCategorias = [...legajo.categorias];
    const cambio = newCategorias[index]
    cambio[propiedad] = nuevoValor;
    setCategorias(newCategorias);
  }

  function Control(props) {

    if (!props.value) {
      return <div>El campo está vacío</div>
    }
    return <div />
  }

  return (
    <div>
      <Table bordered size="sm">
        <thead>
          <tr>
            <th>Categoría</th>
            <th>Fecha desde</th>
            <th>Acciones</th>
            <th>
              <Button onClick={() => addCategoria()}
                title="Agregar categoria" size="sm"
              >
                <BsPlusLg />
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {legajo && legajo.categorias && legajo.categorias.map((eachCategoria, index) =>
            <tr key={index}>
              <td>
                <span>
                  <CategoriaSelector value={eachCategoria.categoria} onSelect={newCategoria => handleCategoriasValue('categoria', newCategoria, index)} />
                  <Control value={eachCategoria.categoria} />
                </span>
              </td>
              <td>
                <DatePicker
                  selected={eachCategoria.fechaDesde}
                  onChange={date => handleCategoriasValue('fechaDesde', date, index)}
                />
                <Control value={eachCategoria.fechaDesde} />
              </td>
              <td>
                <Button onClick={() => removeCategoria(index)}
                  size="sm"
                  title="Quitar"
                  variant="danger">
                  <BsTrash />
                </Button>
              </td>
            </tr>)}
        </tbody>
      </Table>
    </div >
  )
}

export default CategoriaTab;
