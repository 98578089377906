import React from "react";
import { Button, Col, Container, Form, FormLabel, Modal, Row, Table, Tooltip, Dropdown, DropdownButton, Card } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { EditionContext } from "../../../../contexts/editionContext";
import { UIContext } from "../../../../contexts/uiContext";
import LegajoService from "../../../../services/legajo-service";
import LiquidacionService from "../../../../services/liquidacion-service";
import NovedadesService from "../../../../services/novedades-service";
import CaratulaSelector from "../../../shared/caratula-selector";
import ClasificadorLegajoSelector from "../../../shared/clasificador-legajo-selector";
import ConceptoLiquidacionSelector from "../../../shared/concepto-liquidacion-selector";
import LegajoSelector from "../../../shared/legajo-selector";
import DetalleLiquidacion from "../../liquidacion/list/detalleLiquidacion";
import { currencyFormat } from "../../../shared/numbers";
import { BsTrash, BsCurrencyDollar, BsPlusLg } from "react-icons/bs";
import { FiFolderPlus, FiFilePlus } from "react-icons/fi"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ImportadorArchivos from "./importadorArchivos";
import UtilsService from "../../../../services/utils-service";
import CurrencyInput from "../../../shared/currency-input";
import { MdUploadFile } from "react-icons/md";

const emptyConceptos = [];

function NovedadesNew() {

  const { setEdicion, objetoEdicion } = React.useContext(EditionContext)
  const [lineaNovedadSeleccionada, setLineaNovedadSeleccionada] = React.useState({});
  const [lineasNovedades, setLineasNovedades] = React.useState(objetoEdicion ? objetoEdicion.lineasNovedades : [])
  const [caratula, setCaratula] = React.useState(objetoEdicion ? objetoEdicion.caratula : null)
  const [dataParaTotalConcepto, setDataParaTotalConcepto] = React.useState(new Map());

  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false)
  const [showLegajoModal, setShowLegajoModal] = React.useState(false)
  const [showLiquidacionModal, setShowLiquidacionModal] = React.useState(false)
  const [showConceptoModal, setShowConceptoModal] = React.useState(false);
  const [showImportadorArchivoModal, setShowImportadorArchivoModal] = React.useState(false)
  const [liquidacionParaModal, setLiquidacionParaModal] = React.useState(false)
  const [legajoParaModal, setLegajoParaModal] = React.useState(false)
  const [showConceptoMultipleModal, setShowConceptoMultipleModal] = React.useState(false)
  const [showModalValorTotalConcepto, setShowModalValorTotalConcepto] = React.useState(false)
  const [simulacionCalled, setSimulacionCalled] = React.useState(false);

  const { showMessage, setUILoading } = React.useContext(UIContext)
  const [legajoSeleccionadoModal, setLegajoSeleccionadoModal] = React.useState(null)
  const [conceptoLiquidacion, setConceptoLiquidacion] = React.useState(emptyConceptos);
  const [clasificadorLegajo, setClasificadorLegajo] = React.useState(objetoEdicion?.clasificadorLegajo);

  const [queryModalAgregarConcepto, setQueryModalAgregarConcepto] = React.useState(null)
  const [lineasNovedadesModalAgregarConcepto, setLineasNovedadesModalAgregarConcepto] = React.useState([])
  const [rowsModalValuacionMultiple, setRowsModalValuacionMultiple] = React.useState([]);
  const [selectedsModalAgregarConcepto, setSelectedsModalAgregarConcepto] = React.useState([])
  const [valorModificarValuacionMultiple, setValorModificarValuacionMultiple] = React.useState(null)
  const mainDivRef = React.useRef(null);


  React.useEffect(() => {
    if (lineaNovedadSeleccionada._updated) {
      const indice = lineasNovedades.findIndex(lineaNovedad => lineaNovedad.legajo.id === lineaNovedadSeleccionada.legajo.id)
      lineasNovedades[indice] = lineaNovedadSeleccionada;
      setLineasNovedades(lineasNovedades);
    }
  }, [lineaNovedadSeleccionada, lineaNovedadSeleccionada._updated])

  React.useEffect(() => {
    setLineasNovedadesModalAgregarConcepto(lineasNovedades.filter(filtroQuery))
  }, [queryModalAgregarConcepto])

  const ordenadorDeValoresPorCodigo = (a, b) => a.conceptoLiquidacion.codigo - b.conceptoLiquidacion.codigo;

  function lineaNovedadSeleccionadaInLineasNovedades() {
    return lineaNovedadSeleccionada?.legajo?.id && lineasNovedades.find(linea => linea.legajo.id === lineaNovedadSeleccionada.legajo.id)
  }

  function simularLiquidacion() {
    setUILoading(true);
    const novedad = buildNovedadForBackend();
    LiquidacionService.simulateLiquidacion(novedad).then(data => {
      const lineasConSimulacion = lineasNovedades.map(eachLinea => {
        const liquidacion = data.find(liquidacion => liquidacion.legajoId === eachLinea.legajo.id);
        const newLinea = Object.assign({}, eachLinea);
        newLinea.liquidacionSimulada = liquidacion.liquidacionSimulada;
        newLinea.ultimaLiquidacion = liquidacion.ultimaLiquidacion;
        newLinea.error = liquidacion.error
        return newLinea;
      });
      setLineasNovedades(lineasConSimulacion);
    }).catch(error => {
      showMessage(error.response.data.error, 'error')
    }).finally(() => {
      setUILoading(false)
      setSimulacionCalled(true);
    });
  }


  function addUpConcepts() {
    let newDataParaTotalConcepto = new Map();

    for (let i = 0; i < lineasNovedades.length; i++) {
      const linea = lineasNovedades[i];
      

      if (linea.error || !linea.liquidacionSimulada?.conceptosLiquidados) {
        continue;
      }

      const conceptosLiquidados = linea.liquidacionSimulada.conceptosLiquidados;

      conceptosLiquidados.forEach(concepto => {
        const { codigo, nombre, valor } = concepto;

        let total = newDataParaTotalConcepto.get(codigo);
        if (!total) {
          total = {
            nombre,
            codigo,
            valor: 0,
            cantidad: 0
          }
          newDataParaTotalConcepto.set(codigo, total)
        }

        total.valor += valor;
        if (total.valor > 0) {
          total.cantidad = total.cantidad + 1;
        }
      });
    };

    setDataParaTotalConcepto(newDataParaTotalConcepto)
    setShowModalValorTotalConcepto(true)
  }

  function handleLineaNovedadValores(propiedad, nuevoValor, index) {
    setLineaNovedadSeleccionada((currentLineaNovedad) => {
      const newLineaNovedadSeleccionada = { ...currentLineaNovedad };
      newLineaNovedadSeleccionada.valoresConceptoLiquidacion = [
        ...currentLineaNovedad.valoresConceptoLiquidacion
      ];
      newLineaNovedadSeleccionada.valoresConceptoLiquidacion[index] = {
        ...currentLineaNovedad.valoresConceptoLiquidacion[index],
        [propiedad]: nuevoValor
      };
      newLineaNovedadSeleccionada.valoresConceptoLiquidacion.sort(
        ordenadorDeValoresPorCodigo
      );
      newLineaNovedadSeleccionada._updated = true;
      return newLineaNovedadSeleccionada;
    });
  }

  function quitarValorConcepto(codigoValorLiquidacion) {
    setLineaNovedadSeleccionada((currentLineaNovedad) => {
      const clonLineaNovedadSeleccionada = Object.assign({}, currentLineaNovedad);
      clonLineaNovedadSeleccionada.valoresConceptoLiquidacion = clonLineaNovedadSeleccionada.valoresConceptoLiquidacion
        .filter(valor => valor.conceptoLiquidacion.codigo !== codigoValorLiquidacion);
      clonLineaNovedadSeleccionada._updated = true;
      return clonLineaNovedadSeleccionada;
    });
  }

  function toggleConfirmDeleteValor(codigoValorLiquidacion, toggled) {
    setLineaNovedadSeleccionada(currentLineaNovedad => {
      return {
        ...currentLineaNovedad,
        valoresConceptoLiquidacion: currentLineaNovedad.valoresConceptoLiquidacion.map((valor, index) => {
          if (valor.conceptoLiquidacion.codigo === codigoValorLiquidacion) {
            return { ...valor, _toDelete: toggled };
          } else {
            return valor;
          }
        })
      };
    });
  }

  const handleSuccess = message => {
    showMessage(message, 'success');
    setEdicion(false);
  }

  async function agregarConceptosFaltantes(legajo, existingLineaNovedad) {

    const { valoresConceptoLiquidacion } = legajo;

    valoresConceptoLiquidacion.forEach(valorLiquidacion => {

      const relacionConcepto = valorLiquidacion.relaciones.find(eachRelacion => eachRelacion.type === "concepto_liquidacion")

      if (!existingLineaNovedad.valoresConceptoLiquidacion.find(eachValorLinea => eachValorLinea.conceptoLiquidacion.id === relacionConcepto.id)) {

        if (relacionConcepto.metadata?.tiposLiquidacion?.split(',').includes(caratula.tipoLiquidacion)) {

          const conceptoLiquidacion = {
            id: relacionConcepto.id,
            nombre: relacionConcepto.metadata.nombre,
            codigo: relacionConcepto.metadata.codigo
          }
          const newValoresConceptoLiquidacion = {
            importe: valorLiquidacion.importe,
            porcentaje: valorLiquidacion.porcentaje,
            cantidad: valorLiquidacion.cantidad,
            conceptoLiquidacion: conceptoLiquidacion
          }
          existingLineaNovedad.valoresConceptoLiquidacion.push(newValoresConceptoLiquidacion)
        }
      }
    })
    return existingLineaNovedad;
  }

  async function generarNuevaLineaNovedad(legajo) {

    const lineaNovedad = {};
    const newValoresLiquidacion = []
    lineaNovedad.legajo = legajo;

    const { valoresConceptoLiquidacion } = legajo;

    valoresConceptoLiquidacion.forEach(valorConceptoLiquidacion => {

      const relacionConcepto = valorConceptoLiquidacion.relaciones.find(
        eachRelacion => eachRelacion.type === "concepto_liquidacion")

      if (relacionConcepto.metadata?.tiposLiquidacion?.split(',').includes(caratula.tipoLiquidacion)) {

        const conceptoLiquidacion = {
          id: relacionConcepto.id,
          nombre: relacionConcepto.metadata.nombre,
          codigo: relacionConcepto.metadata.codigo
        }

        const valoresLineaNovedad = {
          importe: valorConceptoLiquidacion.importe,
          porcentaje: valorConceptoLiquidacion.porcentaje,
          cantidad: valorConceptoLiquidacion.cantidad,
          conceptoLiquidacion: conceptoLiquidacion
        }
        newValoresLiquidacion.push(valoresLineaNovedad);
      }
    })
    lineaNovedad.valoresConceptoLiquidacion = newValoresLiquidacion;
    return lineaNovedad;
  }

  async function generateOrUpdateLineaNovedadFromLegajo(legajo) {
    const lineaNovedadIndex = lineasNovedades.findIndex(lineaNovedad => lineaNovedad.legajo.id === legajo.id);

    if (lineaNovedadIndex > -1) {
      const lineaNovedad = await agregarConceptosFaltantes(legajo, lineasNovedades[lineaNovedadIndex]);
      return [lineaNovedad, lineaNovedadIndex]
    } else {
      const lineaNovedad = await generarNuevaLineaNovedad(legajo)
      return [lineaNovedad, -1]
    }
  }

  async function confirmarLegajoSeleccionado() {
    const newLineasNovedades = [...lineasNovedades];
    const [lineaNovedad, index] = await generateOrUpdateLineaNovedadFromLegajo(legajoSeleccionadoModal)

    if (index < 0) {
      newLineasNovedades.push(lineaNovedad)
    } else {
      lineasNovedades[index] = lineaNovedad;
    }
    setLineasNovedades(newLineasNovedades);
    setShowLegajoModal(false)
  }

  async function btnTodosLegajosAction() {
    setUILoading(true);

    const newLineasNovedades = [...lineasNovedades];
    //TODO: Solo busca 500 legajos, mejorar.
    const pageNumber = 1;
    const pageSize = 500;
    try {
      const legajosPage = await LegajoService.getLegajos(pageNumber, pageSize, { clasificadorLegajo })

      for (const legajo of legajosPage.content) {
        const [lineaNovedad, index] = await generateOrUpdateLineaNovedadFromLegajo(legajo)

        if (!lineaNovedad) {
          continue
        }

        if (index < 0) {
          newLineasNovedades.push(lineaNovedad)
        } else {
          lineasNovedades[index] = lineaNovedad;
        }
      }
      setLineasNovedades(newLineasNovedades);
    } finally {
      setUILoading(false);
    }
  }

  function buildNovedadForBackend() {
    const newLineasNovedades = []

    lineasNovedades.forEach(lineaNovedad => {
      const newValoresConceptoLiquidacion = []

      lineaNovedad.valoresConceptoLiquidacion.forEach(valor => {
        const valorConceptoLiquidacion = {
          id: valor.id,
          conceptoLiquidacionId: valor.conceptoLiquidacion.id,
          cantidad: valor.cantidad,
          porcentaje: valor.porcentaje,
          importe: valor.importe
        }
        newValoresConceptoLiquidacion.push(valorConceptoLiquidacion)
      })
      const newLineaNovedad = {
        id: lineaNovedad.id,
        legajoId: lineaNovedad.legajo.id,
        valoresConceptoLiquidacion: newValoresConceptoLiquidacion
      }
      newLineasNovedades.push(newLineaNovedad)
    })

    const novedad = {
      id: objetoEdicion ? objetoEdicion.id : null,
      caratulaId: caratula.id,
      lineasNovedades: newLineasNovedades,
      clasificadorLegajo: clasificadorLegajo
    }

    return novedad;
  }

  function submit() {
    setShowConfirmationModal(false);
    setUILoading(true);
    const novedad = buildNovedadForBackend();

    if (objetoEdicion) {
      NovedadesService.updateNovedad(novedad).then(novedadResponse => {
        handleSuccess('Novedad actualizada')
      }).catch(error => {
        showMessage(error.response.data.error, 'error')
      }).finally(() => setUILoading(false));
    } else {
      NovedadesService.createNovedad(novedad).then(data => {
        handleSuccess('Nueva novedad creada')
      }).catch(error => {
        showMessage(error.response.data.error, 'error')
      }).finally(() => setUILoading(false));
    }
  }

  function toggleConfirmDeleteLegajo(rowIndex, toggled) {
    setLineasNovedades(currentLineasNovedades => currentLineasNovedades.map((linea, index) => {
      let newLinea
      if (index === rowIndex) {
        newLinea = { ...linea };
        newLinea._toDelete = toggled
      } else {
        newLinea = linea
      }
      return newLinea;
    }))
  };

  function deleteLegajo(lineaNovedad) {
    setLineaNovedadSeleccionada(() => ({}))
    setLineasNovedades(currentLineasNovedades => {
      return currentLineasNovedades.filter(eachLinea => eachLinea.legajo.id !== lineaNovedad.legajo.id);
    })
  }

  const columns = [
    { dataField: 'legajo.numero', text: 'Número' },
    { dataField: 'legajo.apellido', text: 'Apellido' },
    { dataField: 'legajo.nombre', text: 'Nombre' },
    { dataField: 'legajo.dni', text: 'DNI' },
    {
      text: 'Monto liquidación previa',
      formatter: (cell, row, rowIndex) => {
        if (!row.ultimaLiquidacion) {
          return <i>No existe liquidación previa</i>;
        }
        return <OverlayTrigger
          placement="right"
          overlay={<Tooltip>Click para ver detalle</Tooltip>}
        >
          <Button
            onClick={() => detalle(row.legajo, row.ultimaLiquidacion)}>
            {currencyFormat(row.ultimaLiquidacion.totalNeto)}
          </Button>
        </OverlayTrigger>
      },
    },
    {
      text: 'Monto simulado',
      formatter: (cell, row, rowIndex) => {
        return row.liquidacionSimulada?.totalNeto ?
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>Click para ver detalle</Tooltip>}
          >
            <Button
              onClick={() => detalle(row.legajo, row.liquidacionSimulada)}>
              {currencyFormat(row.liquidacionSimulada.totalNeto)}
            </Button>
          </OverlayTrigger>
          :
          row.error ?
            <div style={{ 'color': '#b9434e' }}>{row.error}</div> : <div>No ha simulado una liquidación aún</div>
      },
    },
    {
      text: 'Eliminar',
      width: '20px',
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            {row._toDelete ? (
              <>
                <span>¿Confirmar?</span>
                <Button
                  id="btnCancelarOpenDeleteLegajo"
                  onClick={() => toggleConfirmDeleteLegajo(rowIndex, false)}
                  size="sm"
                  variant="secondary"
                  style={{ 'marginRight': '5px', 'marginLeft': '5px' }}
                >
                  No
                </Button>
                <Button
                  id="btnEliminarLegajo"
                  onClick={() => deleteLegajo(row)}
                  size="sm"
                  variant="danger">
                  Si
                </Button>
              </>
            ) : (
              <Button id="openConfirmDeleteLegajo"
                onClick={() => {
                  toggleConfirmDeleteLegajo(rowIndex, true)
                }}
                size="sm"
                title="Eliminar"
                variant="danger">
                <BsTrash />
              </Button>
            )}
          </div>
        );
      },
    }
  ];

  const detalle = (legajo, liquidacion) => {
    setLegajoParaModal(legajo)
    setLiquidacionParaModal(liquidacion)
    setShowLiquidacionModal(true);
  }

  const valorOptions = [
    { value: 'importe', label: 'Importe' },
    { value: 'porcentaje', label: 'Porcentaje' },
    { value: 'cantidad', label: 'Cantidad' }
  ]

  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    style: { backgroundColor: 'var(--color-3)' }
  };

  const selectedRow = {
    onClick: (e, row, rowIndex) => {
      const omitirSeleccion = ["btnEliminarLegajo", "openConfirmDeleteLegajo", "btnCancelarOpenDeleteLegajo"]
      if ((e.target?.parentNode?.id && omitirSeleccion.includes(e.target.parentNode.id)) || (e.target?.id && omitirSeleccion.includes(e.target.id))) {
        //El evento viene desde boton eliminar, no queremos seleccionar nada
        return {};
      }
      setLineaNovedadSeleccionada(() => {
        row._updated = false;
        return row;
      });
    }
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedsModalAgregarConcepto((currentSelecteds) => {
        return [...currentSelecteds, row.legajo.id]
      })
    } else {
      setSelectedsModalAgregarConcepto((currentSelecteds) => {
        return currentSelecteds.filter(x => x !== row.legajo.id)
      })
    }
  }

  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      const ids = rows.map(r => r.legajo.id);
      setSelectedsModalAgregarConcepto(() => ids);
    } else {
      setSelectedsModalAgregarConcepto(() => []);
    }
  }

  const selectRowModal = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: selectedsModalAgregarConcepto,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    style: { backgroundColor: 'var(--color-5)' }
  };

  function confirmar() {
    setShowConfirmationModal(true)
  }

  function agregarConceptos() {
    setSelectedsModalAgregarConcepto([]);
    setLineasNovedadesModalAgregarConcepto([...lineasNovedades]);
    setQueryModalAgregarConcepto(null)
    setConceptoLiquidacion(null);
    setShowConceptoModal(true);
  }

  function confirmarConceptos() {
    const newLineasNovedades = [...lineasNovedades];

    for (const eachLinea of newLineasNovedades) {
      if (selectedsModalAgregarConcepto.includes(eachLinea.legajo.id) && !eachLinea.valoresConceptoLiquidacion.find(valor => valor.conceptoLiquidacion.id === conceptoLiquidacion.id)) {
        const valorConceptoLiquidacion = {
          conceptoLiquidacion: conceptoLiquidacion,
          importe: '',
          porcentaje: '',
          cantidad: ''
        }
        eachLinea.valoresConceptoLiquidacion.push(valorConceptoLiquidacion);
        eachLinea.valoresConceptoLiquidacion.sort(ordenadorDeValoresPorCodigo);
      }
    }
    setLineasNovedades(newLineasNovedades);
    setShowConceptoModal(false);
  }

  function filtroQuery(lineaNovedad) {
    if (!queryModalAgregarConcepto) {
      return true
    }
    const cadenas = queryModalAgregarConcepto.split(" ");
    const results = cadenas.map(cadena => containsString(lineaNovedad.legajo.nombre, cadena) ||
      containsString(lineaNovedad.legajo.apellido, cadena) ||
      containsString(lineaNovedad.legajo.numero, cadena) ||
      containsString(lineaNovedad.legajo.dni, cadena)
    );
    return results.findIndex(resultado => resultado === false) === -1;
  }

  function containsString(cadena, contains) {
    return cadena && cadena.toString().toLowerCase().includes(contains.toLowerCase());
  }

  function checkAndSetLegajoEqualsClasificador(legajo) {
    if (legajo?.clasificadorLegajo !== clasificadorLegajo) {
      showMessage('Este Legajo no pertenece a ' + clasificadorLegajo, 'error')
    }
    setLegajoSeleccionadoModal(legajo)
  }

  function submitLineasNovedadesFromImportador(newLineasNovedades) {
    setLineasNovedades(newLineasNovedades);
    setShowImportadorArchivoModal(false);
    showMessage('Archivo importado con éxito', 'success');
  }

  function asociacionMultipleButton() {
    setValorModificarValuacionMultiple('importe');
    setConceptoLiquidacion(null);
    setRowsModalValuacionMultiple([]);
    setShowConceptoMultipleModal(true);
  }

  function setConceptoValuacionMultiple(newConceptoLiquidacion) {
    setConceptoLiquidacion(newConceptoLiquidacion)
    const newRows = [];

    if (newConceptoLiquidacion) {
      lineasNovedades.forEach(linea => {
        const valor = linea.valoresConceptoLiquidacion.find(valor => valor.conceptoLiquidacion.id === newConceptoLiquidacion.id)

        if (valor) {
          const row = {
            nombre: linea.legajo.nombre,
            apellido: linea.legajo.apellido,
            numero: linea.legajo.numero,
            dni: linea.legajo.dni,
            porcentaje: valor.porcentaje,
            cantidad: valor.cantidad,
            importe: valor.importe
          }
          newRows.push(row);
        }
      })
    }
    setRowsModalValuacionMultiple(newRows)
  }

  function valueChangeOnValuacionMultiple(row, value) {
    const index = rowsModalValuacionMultiple.findIndex(valor => valor.numero === row.numero);

    const updatedRows = [...rowsModalValuacionMultiple];
    const updatedObject = {
      ...updatedRows[index],
      [valorModificarValuacionMultiple]: value
    };
    updatedRows[index] = updatedObject;
    setRowsModalValuacionMultiple(updatedRows);
  }

  function confirmarValuacionMultipleModal() {
    const updatedLineaNovedad = lineasNovedades.map(lineaNovedadItem => {
      lineaNovedadItem.valoresConceptoLiquidacion.forEach(valorConceptoLiquidacion => {

        if (valorConceptoLiquidacion.conceptoLiquidacion.id === conceptoLiquidacion.id) {
          const rowModalValuacionMultiple = rowsModalValuacionMultiple.find(row => {
            return row.numero === lineaNovedadItem.legajo.numero;
          });

          if (rowModalValuacionMultiple) {
            let nuevoValor = null;

            const newValueRaw = rowModalValuacionMultiple[valorModificarValuacionMultiple];
            if (newValueRaw !== undefined && newValueRaw !== null) {
              if (typeof newValueRaw === "string") {
                nuevoValor = parseFloat(newValueRaw.replaceAll(',', '.'));
              } else if (typeof newValueRaw === "number") {
                nuevoValor = newValueRaw;
              }
            }

            switch (valorModificarValuacionMultiple) {
              case "importe":
                valorConceptoLiquidacion.importe = nuevoValor;
                break;
              case "porcentaje":
                valorConceptoLiquidacion.porcentaje = nuevoValor;
                break;
              case "cantidad":
                valorConceptoLiquidacion.cantidad = nuevoValor;
                break;
              default:
                break;
            }
          }
        }
      });
      return lineaNovedadItem;
    });

    setShowConceptoMultipleModal(false)
    setLineasNovedades(updatedLineaNovedad);
  }



  return (
    <div ref={mainDivRef}>
      <Container className="central-div">
        <h1>Nueva Novedad</h1>
      </Container>
      <Container className="central-div">
        <Row>
          <Col>
            <Form.Group controlId="caratula">
              <FormLabel>Carátula</FormLabel>
              <CaratulaSelector value={caratula} onSelect={newCaratula => setCaratula(newCaratula)} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="clasificadorLegajo">
              <FormLabel>Clasificador de Legajo</FormLabel>
              <ClasificadorLegajoSelector value={clasificadorLegajo}
                required
                title={lineasNovedades.length !== 0 ? 'Clasificador no puede modicarse porque ya hay Legajos asociados del tipo ' + clasificadorLegajo : ''}
                disabled={lineasNovedades.length !== 0}
                onSelect={value => setClasificadorLegajo(value)} />
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <Container className="central-div">
        <div className="title-and-buttons">
          <h3 className="title">Legajos a liquidar</h3>
          <span className="buttons">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>{!clasificadorLegajo ? 'Seleccione un Clasificador para usar este boton' : 'Asocia todos los Legajos activos del tipo ' + clasificadorLegajo}</Tooltip>}
            >
              <span>
                <Button className="d-inline-flex align-items-center"
                  disabled={!clasificadorLegajo}
                  onClick={() => btnTodosLegajosAction()}
                >
                  <FiFolderPlus />
                  <span className="ms-1">Todos</span>
                </Button>
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>{!clasificadorLegajo ? 'Seleccione un Clasificador para usar este boton' : 'Asociar un Legajo individual'}</Tooltip>}
            >
              <span>
                <Button className="d-inline-flex align-items-center"
                  onClick={() => setShowLegajoModal(true)}
                  disabled={!clasificadorLegajo}
                >
                  <FiFilePlus />
                  <span className="ms-1">Un Legajo</span>
                </Button>
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Simular liquidación para esta novedad</Tooltip>}
            >
              <span>
                <Button
                  disabled={!caratula || !clasificadorLegajo || !lineasNovedades.length}
                  className="d-inline-flex align-items-center" onClick={() => simularLiquidacion()}>
                  <BsCurrencyDollar />
                  <span className="ms-1">Simular</span>
                </Button>
              </span>
            </OverlayTrigger>
            <span>
              <Button
                disabled={!simulacionCalled}
                className="d-inline-flex align-items-center" onClick={() => addUpConcepts()}>
                <span className="ms-1">Totalizadores</span>
              </Button>
            </span>
          </span>
        </div>
        <Row>
          <Col>
            <div style={{ "overflowY": "auto", "maxHeight": "300px" }}>
              <BootstrapTable keyField='legajo.id'
                data={lineasNovedades}
                columns={columns}
                selectRow={selectRow}
                remote={true}
                rowEvents={selectedRow}
                hover
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="central-div">
        <div className="title-and-buttons">
          <h3 className="title">Conceptos del Legajo Seleccionado</h3>
          <span className="buttons">
            <DropdownButton id="dropdown-basic-button" title="Acciones sobre Conceptos" variant="success" style={{ 'marginBottom': '5px' }}>
              <Dropdown.Item className="custom-dropdown-item" onClick={() => agregarConceptos()}>
                <FiFolderPlus style={{ "marginRight": "8px" }} />
                Agregar nuevos Conceptos
              </Dropdown.Item>
              <Dropdown.Item className="custom-dropdown-item" onClick={() => asociacionMultipleButton()}>
                <BsCurrencyDollar style={{ "marginRight": "8px" }} />
                Modificacion masiva de valores
              </Dropdown.Item>
              <Dropdown.Item className="custom-dropdown-item" onClick={() => setShowImportadorArchivoModal(true)}>
                <MdUploadFile style={{ "marginRight": "8px" }} />
                Importar archivo con valores
              </Dropdown.Item>
            </DropdownButton>
          </span>
        </div>
        <Table bordered size="sm">
          <thead>
            <tr>
              <th>Código</th>
              <th>Nombre</th>
              <th>Porcentaje</th>
              <th>Cantidad</th>
              <th>Importe</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {lineaNovedadSeleccionada?.valoresConceptoLiquidacion && lineaNovedadSeleccionadaInLineasNovedades() &&
              lineaNovedadSeleccionada.valoresConceptoLiquidacion.map((eachValorConcepto, index) => {
                return <tr key={lineaNovedadSeleccionada.legajo.id + '-' + eachValorConcepto.conceptoLiquidacion.codigo} >
                  <td>{eachValorConcepto.conceptoLiquidacion.codigo}</td>
                  <td>{eachValorConcepto.conceptoLiquidacion.nombre}</td>
                  <td>
                    <Form.Control className="text-align-center" size="sm" type="text"
                      value={eachValorConcepto.porcentaje} onChange={e => handleLineaNovedadValores('porcentaje', UtilsService.numbersOnly(e.target.value), index)} />
                  </td>
                  <td>
                    <Form.Control className="text-align-center" size="sm" type="text"
                      value={eachValorConcepto.cantidad} onChange={e => handleLineaNovedadValores('cantidad', UtilsService.numbersOnly(e.target.value), index)} /></td>
                  <td>
                    <CurrencyInput size="sm" value={eachValorConcepto.importe || ''}
                      onValueChange={value => handleLineaNovedadValores('importe', value.floatValue, index)} />
                  </td>
                  <td>{
                    eachValorConcepto._toDelete ?
                      (<>
                        <span>¿Confirmar?</span>
                        <Button
                          onClick={() => toggleConfirmDeleteValor(eachValorConcepto.conceptoLiquidacion.codigo, false)}
                          size="sm"
                          variant="secondary"
                          style={{ 'marginRight': '5px', 'marginLeft': '5px' }}
                        >
                          No
                        </Button>
                        <Button
                          onClick={() => quitarValorConcepto(eachValorConcepto.conceptoLiquidacion.codigo)}
                          size="sm"
                          variant="danger">
                          Si
                        </Button>
                      </>) : (
                        <Button onClick={() => toggleConfirmDeleteValor(eachValorConcepto.conceptoLiquidacion.codigo, true)}
                          size="sm"
                          title="Quitar"
                          variant="danger">
                          <BsTrash />
                        </Button>
                      )}
                  </td>
                </tr>
              })}
          </tbody>
        </Table>
      </Container>
      <Container className="central-div">
        <Row className="row-mb-5">
          <Col>
            <div className="central-div">
              <Link to={"/"}>
                <Button onClick={() => setEdicion(false)} size="lg" variant="danger" className="button-mr-5">
                  Cancelar
                </Button>
              </Link>
              <Button size="lg" type="submit" disabled={!caratula} onClick={() => { confirmar() }} >
                Confirmar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showLegajoModal} onHide={() => setShowLegajoModal(false)} animation={false} container={mainDivRef.current}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar legajo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LegajoSelector
            onSelect={legajo => checkAndSetLegajoEqualsClasificador(legajo)} readOnly={false} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLegajoModal(false)}>
            Cerrar
          </Button>
          <Button disabled={legajoSeleccionadoModal?.clasificadorLegajo !== clasificadorLegajo} variant="primary" onClick={confirmarLegajoSeleccionado}>
            Agregar legajo.
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showConceptoModal} onHide={() => setShowConceptoModal(false)} animation={false} container={mainDivRef.current}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar conceptos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Seleccionar concepto</Form.Label>
                <ConceptoLiquidacionSelector
                  tipoLiquidacion={caratula?.tipoLiquidacion}
                  value={conceptoLiquidacion}
                  onSelect={newConceptoLiquidacion => {
                    setConceptoLiquidacion(newConceptoLiquidacion)
                  }} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Filtrar Legajos</Form.Label>
                <Form.Control className="text-align-center" size="sm" type="text" placeholder="Escriba aquí para filtrar legajos." value={queryModalAgregarConcepto} onChange={e => setQueryModalAgregarConcepto(e.target.value)} />
              </Form.Group>
            </Col>
          </Row>
          <BootstrapTable hover keyField='legajo.id'
            data={lineasNovedadesModalAgregarConcepto}
            columns={[
              { dataField: 'legajo.numero', text: 'Número' },
              { dataField: 'legajo.nombre', text: 'Nombre' },
              { dataField: 'legajo.dni', text: 'DNI' },
              { dataField: 'legajo.apellido', text: 'Apellido' },
            ]}
            selectRow={selectRowModal}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConceptoModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" disabled={!conceptoLiquidacion || selectedsModalAgregarConcepto.length === 0} onClick={() => confirmarConceptos()} >
            Agregar concepto.
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showLiquidacionModal} onHide={() => setShowLiquidacionModal(false)} animation={false} container={mainDivRef.current}>
        <Modal.Header closeButton>
          <Modal.Title>Simulacion de liquidacion</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }} >
          <DetalleLiquidacion liquidacion={liquidacionParaModal} legajo={legajoParaModal} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLiquidacionModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)} animation={false} container={mainDivRef.current}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Seguro que quieres generar esta novedad?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={submit}>
            Guardar novedad.
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        <Modal size="lg"
          show={showConceptoMultipleModal}
          onHide={() => setShowConceptoMultipleModal(false)}
          animation={false}
          container={mainDivRef.current}>
          <Modal.Header closeButton>
            <Modal.Title>Modificación masiva de valores</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={8}>
                <Form.Group>
                  <Form.Label>Seleccionar concepto</Form.Label>
                  <ConceptoLiquidacionSelector
                    tipoLiquidacion={caratula?.tipoLiquidacion}
                    value={conceptoLiquidacion}
                    onSelect={newConceptoLiquidacion => {
                      setConceptoValuacionMultiple(newConceptoLiquidacion)
                    }} />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <FormLabel>Valor a modificar</FormLabel>
                  <Form.Select
                    className='select-dark'
                    value={valorModificarValuacionMultiple}
                    onChange={e => {
                      setValorModificarValuacionMultiple(e.target.value)
                    }}
                  >
                    {valorOptions.map(option =>
                      <option value={option.value}>{option.label}</option>
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Table className="table table-hover">
              <thead>
                <tr>
                  <th>Número</th>
                  <th>Nombre</th>
                  <th>DNI</th>
                  <th>Apellido</th>
                  <th>Valor a modificar</th>
                </tr>
              </thead>
              <tbody>
                {rowsModalValuacionMultiple && rowsModalValuacionMultiple.map(row => (
                  <tr key={row.numero}>
                    <td>{row.numero}</td>
                    <td>{row.nombre}</td>
                    <td>{row.dni}</td>
                    <td>{row.apellido}</td>
                    <td>
                      <Form.Control className="text-align-center" size="sm" type="text"
                        value={row[valorModificarValuacionMultiple] || ''}
                        onChange={e => valueChangeOnValuacionMultiple(row, e.target.value)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConceptoMultipleModal(false)}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={confirmarValuacionMultipleModal}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div>
        <Modal size="lg"
          show={showModalValorTotalConcepto}
          onHide={() => setShowModalValorTotalConcepto(false)}
          animation={false}
          container={mainDivRef.current}>
          <Modal.Header closeButton>
            <Modal.Title>Totales por concepto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table className="table table-hover" bordered>
              <thead>
                <tr>
                  <th>Codigo</th>
                  <th>Concepto</th>
                  <th>Valor TOTAL</th>
                  <th>Cantidad legajos liquidaron</th>
                </tr>
              </thead>
              <tbody>
                {[...dataParaTotalConcepto.keys()].sort((a, b) => a - b).map(codigo => (
                  <tr key={codigo}>
                    <td>{codigo}</td>
                    <td>{dataParaTotalConcepto.get(codigo).nombre}</td>
                    <td>{currencyFormat(dataParaTotalConcepto.get(codigo).valor)}</td>
                    <td>{dataParaTotalConcepto.get(codigo).cantidad}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModalValorTotalConcepto(false)}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <ImportadorArchivos lineasNovedades={lineasNovedades}
        onSubmitLineasNovedades={submitLineasNovedadesFromImportador}
        caratula={caratula}
        setShowModal={setShowImportadorArchivoModal}
        showModal={showImportadorArchivoModal}
        mainDivRef={mainDivRef}
      />
    </div >
  )
}

export default NovedadesNew;