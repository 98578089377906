import React from "react";
import { Form } from "react-bootstrap";
import { UIContext } from "../../contexts/uiContext";
import ClasificadorLegajoService from "../../services/clasificador-legajo-service";

function ClasificadorLegajoSelector(props) {

  const [clasificadoresOptions, setClasificadoresOptions] = React.useState([]);
  const { showMessage } = React.useContext(UIContext);

  React.useEffect(() => {
    buscar()
  }, [])

  function buscar() {
    ClasificadorLegajoService.getClasificadores(1, 500)
      .then(data => {
        setClasificadoresOptions(data)
      })
      .catch((error) => {
        showMessage('Ocurrió un error al recuperar los clasificadores', 'error')
      })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Form.Select
        isClearable
        title={props.title}
        disabled={props.disabled}
        value={props.value}
        required
        onChange={e => props.onSelect(e?.target?.value || null)} >
        <option value={null}></option>
        {clasificadoresOptions.map(option =>
          <option value={option.nombre}>{option.nombre}</option>
        )}
      </Form.Select>
    </div>
  )
}

export default ClasificadorLegajoSelector;
