import React from 'react';
import { Table } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { BsPlusLg, BsTrash } from 'react-icons/bs';
import { LegajoContext } from '../../../../contexts/legajoContext';
import DatePicker from '../../../shared/date-picker';

const emptyLicencia = {
  tipoLicencia: '',
  fechaDesde: null,
  fechaHasta: null,
}

function LicenciaTab() {

  const tiposLicenciasOptions = [
    { value: '', label: '' },
    { value: 'VACACIONES', label: 'Vacaciones' },
    { value: 'ENFERMEDAD', label: 'Enfermedad' }
  ]
  const { legajo, setLegajo } = React.useContext(LegajoContext);

  function setLicencias(licencias) {
    const newLegajo = {
      ...legajo,
      licencias: licencias
    }
    setLegajo(newLegajo);
  }

  function addLicencia() {
    const newLicenciaList = [...legajo.licencias]
    newLicenciaList.unshift(Object.assign({}, emptyLicencia))
    setLicencias(newLicenciaList);
  }

  function removeLicencia(index) {
    const newLicenciaList = [...legajo.licencias]
    newLicenciaList.splice(index, 1)
    setLicencias(newLicenciaList)
  }

  function handleLicenciasValue(propiedad, nuevoValor, index) {
    const newLicencias = [...legajo.licencias];
    const cambio = newLicencias[index]
    cambio[propiedad] = nuevoValor;
    setLicencias(newLicencias);
  }


  function Control(props) {
    if (!props.value) {
      return <div>El campo está vacío</div>
    }
    return <div />
  }

  return (
    <div>
      <t2>Dar de alta una licencia</t2>
      <Table bordered size="sm">
        <thead>
          <tr>
            <th>Tipo de licencia</th>
            <th>Fecha inicio</th>
            <th>Fecha finalización</th>

            <th>
              <Button onClick={() => addLicencia()}
                size="sm"
                title="Agregar Licencia"
              >
                <BsPlusLg />
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {legajo && legajo.licencias && legajo.licencias.map((eachLicencia, index) =>
            <tr>
              <td>
                <select
                  className='form-control text-align-center'
                  value={eachLicencia.tipoLicencia}
                  onChange={e => {
                    handleLicenciasValue('tipoLicencia', e.target.value, index)
                  }}>
                  {tiposLicenciasOptions.map(option =>
                    <option value={option.value}>{option.label}</option>
                  )}
                </select>
              </td>
              <td>
                <DatePicker
                  selected={eachLicencia.fechaDesde}
                  onChange={date => handleLicenciasValue('fechaDesde', date, index)}
                />
                <Control value={eachLicencia.fechaDesde} />
              </td>
              <td>
                <DatePicker
                  selected={eachLicencia.fechaHasta}
                  onChange={date => handleLicenciasValue('fechaHasta', date, index)}
                />
                <Control value={eachLicencia.fechaHasta} />
              </td>
              <td>
                <Button onClick={() => removeLicencia(index)}
                  size="sm"
                  title="Quitar"
                  variant="danger">
                  <BsTrash />
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

    </div>



  )
}

export default LicenciaTab;