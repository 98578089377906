import React from 'react';
import { Table, Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { BsPlusLg, BsTrash } from 'react-icons/bs';
import { LegajoContext } from '../../../../contexts/legajoContext';
import DatePicker from '../../../shared/date-picker';

const emptyInasistencia = {
  tipoInasistencia: '',
  fechaInasistencia: null,
}

function InasistenciaTab() {

  const { legajo, setLegajo } = React.useContext(LegajoContext);

  const inasistenciaOptions = [
    { value: '', label: '' },
    { value: 'JUSTIFICADA', label: 'Inasistencia Justificada' },
    { value: 'NO_JUSTIFICADA', label: 'Inasistencia No justificada' },
    { value: 'LLEGADA_TARDE_JUSTIFICADA', label: 'Llegada tarde justificada' },
    { value: 'LLEGADA_TARDE_NO_JUSTIFICADA', label: 'Llegada tarde no justificada' }
  ]

  function setInasistencias(inasistencias) {
    const newLegajo = {
      ...legajo,
      inasistencias: inasistencias
    }
    setLegajo(newLegajo);
  }

  function addInasistencia() {
    const newInasistencias = [...legajo.inasistencias]
    newInasistencias.unshift(Object.assign({}, emptyInasistencia))
    setInasistencias(newInasistencias);
  }

  function removeInasistencia(index) {
    const newInasistencias = [...legajo.inasistencias]
    newInasistencias.splice(index, 1)
    setInasistencias(newInasistencias)
  }

  function handleInasistenciasValue(propiedad, nuevoValor, index) {
    const newInasistencias = [...legajo.inasistencias];
    const cambio = newInasistencias[index]
    cambio[propiedad] = nuevoValor;
    setInasistencias(newInasistencias);
  }

  function Control(props) {

    if (!props.value) {
      return <div>El campo está vacío</div>
    }
    return <div />
  }

  return (
    <div>
      <Table bordered size="sm">
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Fecha</th>
            <th>
              <Button onClick={() => addInasistencia()}
                size="sm"
                title="Agregar inasistencia"
              >
                <BsPlusLg />
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {legajo?.inasistencias && legajo.inasistencias.map((eachInasistencia, index) =>
            <tr>
              <td>
                <Form.Select value={eachInasistencia.tipoInasistencia}
                  onChange={e => {
                    handleInasistenciasValue('tipoInasistencia', e.target.value, index)
                  }}>
                  {inasistenciaOptions.map(option =>
                    <option value={option.value}>{option.label}</option>
                  )}
                </Form.Select>
              </td>
              <td>
                <DatePicker
                  selected={eachInasistencia.fechaInasistencia}
                  onChange={date => handleInasistenciasValue('fechaInasistencia', date, index)}
                />
                <Control value={eachInasistencia.fechaInasistencia} />
              </td>
              <td>
                <Button onClick={() => removeInasistencia(index)}
                  size="sm"
                  title="Quitar"
                  variant="danger">
                  <BsTrash />
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

    </div>
  )
}

export default InasistenciaTab;