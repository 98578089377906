import axios from 'axios';
import UtilsService from './utils-service'

function fromServer(liquidacion) {
  const caratulaDocument = liquidacion.relaciones.find(relacion => relacion.type === "caratula")
  const legajoDocument = liquidacion.relaciones.find(relacion => relacion.type === "legajo")
  liquidacion.caratula = {
    nombre: caratulaDocument.metadata.nombre
  }
  liquidacion.legajo = {
    nombre: legajoDocument.metadata.nombre,
    apellido: legajoDocument.metadata.apellido,
    dni: legajoDocument.metadata.dni,
    numero: legajoDocument.metadata.numero,
    fechaIngreso: legajoDocument.metadata.fechaIngreso,
    clasificadorLegajo: legajoDocument.metadata.clasificadorLegajo
  }
  return liquidacion
}

const LiquidacionService = {

  getLiquidaciones: (page, size, filters) => {
    const params = {
      page: page ? page - 1 : 0,
      size: size ? size : 10,
      ...filters
    }
    return axios.get('/api/liquisueldos/liquidacion', { params }).then(resp => {
      resp.data.content.forEach(fromServer);
      return resp.data
    })
  },
  deleteLiquidaciones: (filters) => {
    const params = { ...filters }
    return axios.delete('/api/liquisueldos/liquidacion/delete', { params }).then(resp => resp.data)
  },
  getLiquidacion: id => {
    return axios.get('/api/liquisueldos/liquidacion/' + id).then(resp => resp.data)
  },
  createLiquidacion: liquidacion => {
    return axios.post('/api/liquisueldos/liquidacion', liquidacion).then(resp => resp.data)
  },
  simulateLiquidacion: novedad => {
    return axios.post('/api/liquisueldos/liquidacion/simular', novedad).then(resp => resp.data)
  },
  updateLiquidacion: liquidacion => {
    return axios.put('/api/liquisueldos/liquidacion', liquidacion).then(resp => resp.data)
  },
  imprimirLiquidaciones: (idCaratula, clasificadorLegajo) => {
    let url = `/api/liquisueldos/liquidacion/imprimir-todos?caratula-id=${idCaratula}`;
    if (clasificadorLegajo) {
      url += `&clasificador-legajo=${clasificadorLegajo}`;
    }
    return UtilsService.getUrlDataFromPdfEndpoint(url)
  },
  getReporteLiquidacion: id => {
    return UtilsService.getUrlDataFromPdfEndpoint(`/api/liquisueldos/liquidacion/${id}/reporte`)
  },
  postAutoImpresion: body => {
    return UtilsService.getUrlDataFromPdfEndpoint('/api/liquisueldos/liquidacion/auto-impresion', "POST", body)
  },


}

export default LiquidacionService;