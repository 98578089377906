import React from "react"
import { Form } from "react-bootstrap";
import CurrencyFormat from 'react-currency-format';

function CurrencyInput(props) {

  return (
    <CurrencyFormat
      className="text-align-center"
      fixedDecimalScale={true}
      prefix={'$ '}
      decimalScale={2}
      customInput={Form.Control}
      {...props}
    />
  )
}

export default CurrencyInput;