import React from 'react'
import { Button, Col, Container, Form } from 'react-bootstrap'
import Row from "react-bootstrap/Row";
import ConceptoLiquidacionService from '../../../../services/concepto-liquidacion-service';
import { BsArrow90DegRight, BsTrash } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import { FaRegCopy } from "react-icons/fa";
import ConceptoCloningModal from './conceptoCloningModal';
import { LegajoContext } from '../../../../contexts/legajoContext';
import ConceptoLiquidacionSelector from '../../../shared/concepto-liquidacion-selector';
import UtilsService from '../../../../services/utils-service';
import CurrencyInput from '../../../shared/currency-input';
import { UIContext } from '../../../../contexts/uiContext';

function ConceptoFijoTab() {

  const { legajo, setLegajo } = React.useContext(LegajoContext);
  const [showConceptoCloningModal, setShowConceptoCloningModal] = React.useState(false);
  const [conceptosLiquidacionOptions, setConceptosLiquidacionOptions] = React.useState([])
  const [conceptoLiquidacionSeleccionado, setConceptoLiquidacionSeleccionado] = React.useState(null);
  const mainDivRef = React.useRef(null);
  const { showMessage } = React.useContext(UIContext);

  const ordenarValoresPorCodigo = (a, b) => a.conceptoLiquidacion.codigo - b.conceptoLiquidacion.codigo

  React.useEffect(() => {
    ConceptoLiquidacionService.getConceptosLiquidacion().then(data => {
      setConceptosLiquidacionOptions(data.content.map(conceptoLiquidacion => ({ value: conceptoLiquidacion, label: conceptoLiquidacion.nombre })))
    })
    markExcluyentes(legajo.valoresConceptoLiquidacion);
  }, [])

  function setValoresLegajo(valores) {

    setLegajo((currentLegajo) => {
      return {
        ...currentLegajo,
        valoresConceptoLiquidacion: valores
      }
    });
  }

  function hasValidConceptosExcluyentes(valorConceptoLiquidacion) {
    const conceptosExcluyentes = valorConceptoLiquidacion?.conceptoLiquidacion?.conceptosExcluyentes;
    return Array.isArray(conceptosExcluyentes) && conceptosExcluyentes.length > 0;
  }

  function markExcluyentes(valoresConceptoLiquidacion) {
    const excluyentesIds = new Set();

    valoresConceptoLiquidacion.forEach(eachValorConceptoLiquidacion => {
      if (hasValidConceptosExcluyentes(eachValorConceptoLiquidacion)) {
        const conceptosExcluyentes = eachValorConceptoLiquidacion.conceptoLiquidacion.conceptosExcluyentes;
        conceptosExcluyentes.forEach(eachExcluyente => {
          excluyentesIds.add(eachExcluyente.id);
        });
      }
    });

    let count = 0;
    valoresConceptoLiquidacion.forEach(eachValorConceptoLiquidacion => {
      if (excluyentesIds.has(eachValorConceptoLiquidacion.conceptoLiquidacion.id)) {
        count++;
        eachValorConceptoLiquidacion._excluyentePresent = true;
      } else {
        eachValorConceptoLiquidacion._excluyentePresent = false;
      }
    });
    if (count > 0) {
      showMessage("Hay conceptos excluyentes", 'error');
    }
  }

  function handleValores(propiedad, nuevoValor, index) {
    const newValoresConceptoLiquidacion = [...legajo.valoresConceptoLiquidacion];
    const cambio = newValoresConceptoLiquidacion[index]
    cambio[propiedad] = nuevoValor;
    setValoresLegajo(newValoresConceptoLiquidacion);
  }

  function quitarConceptoLiquidacion(codigoValorLiquidacion) {
    const valorConceptoLiquidacionRemove = legajo.valoresConceptoLiquidacion.filter(valor => valor.conceptoLiquidacion.codigo !== codigoValorLiquidacion);
    valorConceptoLiquidacionRemove.sort(ordenarValoresPorCodigo);
    markExcluyentes(valorConceptoLiquidacionRemove);
    setValoresLegajo(valorConceptoLiquidacionRemove);
  }

  function toggleConfirmDeleteValor(codigoValorLiquidacion, toggled) {
    const newValoresToSet = legajo.valoresConceptoLiquidacion.map((valor, index) => {
      if (valor.conceptoLiquidacion.codigo === codigoValorLiquidacion) {
        return { ...valor, _toDelete: toggled };
      } else {
        return valor;
      }
    })
    setValoresLegajo(newValoresToSet)
  }

  function addConceptoLiquidacion() {
    const existe = legajo.valoresConceptoLiquidacion.find(
      eachValorConcepto => conceptoLiquidacionSeleccionado.id === eachValorConcepto.conceptoLiquidacion.id);
    if (!existe) {
      const newValoresConceptosLiquidacionList = [...legajo.valoresConceptoLiquidacion];
      const nuevoValor = {
        conceptoLiquidacion: conceptoLiquidacionSeleccionado
      }
      newValoresConceptosLiquidacionList.push(nuevoValor);
      newValoresConceptosLiquidacionList.sort(ordenarValoresPorCodigo)
      markExcluyentes(newValoresConceptosLiquidacionList);
      setValoresLegajo(newValoresConceptosLiquidacionList);
    }
    setConceptoLiquidacionSeleccionado(null);
  }

  return (
    <div ref={mainDivRef}>
      <Container className="central-div">
        <Row>
          <Col md={9}>
            Seleccione un concepto para agregar al legajo:
          </Col>
          <Col md={3}>
            Clonar conceptos desde otro legajo:
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <ConceptoLiquidacionSelector options={conceptosLiquidacionOptions}
              value={conceptoLiquidacionSeleccionado}
              onSelect={(newconceptoLiquidacionSeleccionado, e) => setConceptoLiquidacionSeleccionado(newconceptoLiquidacionSeleccionado)}
            />
          </Col>
          <Col md={1}>
            <Button disabled={!conceptoLiquidacionSeleccionado}
              onClick={() => addConceptoLiquidacion()}
            >
              <BsArrow90DegRight style={{ transform: 'rotate(90deg)' }} />
            </Button>
          </Col>
          <Col md={3}>
            <Button variant="success" onClick={() => setShowConceptoCloningModal(true)}>
              <FaRegCopy />
            </Button>
          </Col>
        </Row>

        <Row>
          <Table bordered size="sm">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Código</th>
                <th>Porcentaje</th>
                <th>Cantidad</th>
                <th>Importe</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {legajo?.valoresConceptoLiquidacion && legajo.valoresConceptoLiquidacion.map((eachValorConcepto, index) => {
                const rowStyle = eachValorConcepto._excluyentePresent ? { backgroundColor: '#b9434e' } : {};
                return (
                  <tr key={eachValorConcepto.conceptoLiquidacion.codigo} style={rowStyle}>
                    <td>{eachValorConcepto.conceptoLiquidacion.nombre}</td>
                    <td>{eachValorConcepto.conceptoLiquidacion.codigo}</td>
                    <td>
                      <Form.Control
                        className="text-align-center"
                        size="sm"
                        type="text"
                        value={eachValorConcepto.porcentaje}
                        onChange={e => handleValores('porcentaje', UtilsService.numbersOnly(e.target.value), index)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        className="text-align-center"
                        size="sm"
                        type="text"
                        value={eachValorConcepto.cantidad}
                        onChange={e => handleValores('cantidad', UtilsService.numbersOnly(e.target.value), index)}
                      />
                    </td>
                    <td>
                      <CurrencyInput
                        size="sm"
                        value={eachValorConcepto.importe || ''}
                        onValueChange={value => handleValores('importe', value.floatValue, index)}
                      />
                    </td>
                    <td>
                      {eachValorConcepto._toDelete ? (
                        <>
                          <span>¿Confirmar?</span>
                          <Button
                            onClick={() => toggleConfirmDeleteValor(eachValorConcepto.conceptoLiquidacion.codigo, false)}
                            size="sm"
                            variant="secondary"
                            style={{ marginRight: '5px', marginLeft: '5px' }}
                          >
                            No
                          </Button>
                          <Button
                            onClick={() => quitarConceptoLiquidacion(eachValorConcepto.conceptoLiquidacion.codigo)}
                            size="sm"
                            variant="danger"
                          >
                            Si
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => toggleConfirmDeleteValor(eachValorConcepto.conceptoLiquidacion.codigo, true)}
                          size="sm"
                          title="Quitar"
                          variant="danger"
                        >
                          <BsTrash />
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Row>
      </Container>

      <ConceptoCloningModal showConceptoCloningModal={showConceptoCloningModal}
        container={mainDivRef.current}
        setShowConceptoCloningModal={setShowConceptoCloningModal}
        setValoresLegajo={setValoresLegajo} />

    </div >
  )
}

export default ConceptoFijoTab;