import axios from "axios";

function fromServer(parametroConstante) {
  parametroConstante.valores.forEach(parametro => parametro.fechaHasta = new Date(parametro.fechaHasta));
  parametroConstante.valores.sort((a, b) => b.fechaHasta.getTime() - a.fechaHasta.getTime())

  const today = new Date()

  const valoresMasVigentes = parametroConstante.valores.filter(
    eachValor => today.getTime() <= eachValor.fechaHasta.getTime());

  if (valoresMasVigentes.length > 0) {
    parametroConstante.valorVigente = valoresMasVigentes[valoresMasVigentes.length - 1].valor
    parametroConstante.vigenteHasta = valoresMasVigentes[valoresMasVigentes.length - 1].fechaHasta
  } else {
    parametroConstante.valorVigente = parametroConstante.valores[0].valor
    parametroConstante.vigenteHasta = parametroConstante.valores[0].fechaHasta
  }
  return parametroConstante
}

const ParametroConstanteService = {

  getParametrosConstantes: (page, size) => {
    const params = {
      page: page ? page - 1 : 0,
      size: size ? size : 0
    }
    return axios.get('/api/liquisueldos/parametro-constante', { params }).then(resp => {
      resp.data.content.forEach(fromServer);
      return resp.data
    })
  },
  getParametroConstante: id => {
    return axios.get('/api/liquisueldos/parametro-constante/' + id).then(resp => fromServer(resp.data))
  },
  createParametroConstante: parametro => {
    return axios.post('/api/liquisueldos/parametro-constante', parametro).then(resp => resp.data)
  },
  updateParametroConstante: parametro => {
    return axios.put('/api/liquisueldos/parametro-constante', parametro).then(resp => resp.data)
  }
}

export default ParametroConstanteService;