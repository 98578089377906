import React from "react";
import { Form } from "react-bootstrap";
import { UIContext } from "../../contexts/uiContext";
import BaseDarkSelect from "./base-dark-select";

function TipoLiquidacionSelector(props) {

  const { showMessage } = React.useContext(UIContext);

  const tipoLiquidacionOptions = [
    { value: 'NORMAL', label: 'NORMAL' },
    { value: 'COMPLEMENTARIA', label: 'COMPLEMENTARIA' },
    { value: 'SAC', label: 'SAC' }
  ]

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {props.isMulti === true ? <BaseDarkSelect
        value={props.value}
        options={tipoLiquidacionOptions}
        isMulti={true}
        isClearable
        required
        onChange={e => {
          props.onSelect(e)
        }} >
      </BaseDarkSelect> :
        <BaseDarkSelect
          value={{ value: props.value, label: props.value ? props.value : null }}
          options={tipoLiquidacionOptions}
          isMulti={false}
          isClearable
          required
          onChange={e => {
            props.onSelect(e?.value || null)
          }} >
        </BaseDarkSelect>
      }
    </div >
  )
}

export default TipoLiquidacionSelector;