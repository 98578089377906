import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import "flexlayout-react/style/dark.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import setupAxiosInterceptors from './config/axios-interceptor';

import { UserProvider } from './contexts/userContext'
import { UIProvider } from './contexts/uiContext'

setupAxiosInterceptors(() => document.dispatchEvent(new Event('invalidToken')));

ReactDOM.render(
  <BrowserRouter basename="/">
    <UserProvider>
      <UIProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </UIProvider>
    </UserProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
