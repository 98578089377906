import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from 'react-bootstrap-table2-filter';

function AdminTable(props) {

  const { data, columns, totalSize, page, setPage, pageSize, setPageSize, onTableChange } = props;

  columns.forEach(column => {
    if (column.filter?.Filter) {
      if (!column.filter.props) {
        column.filter.props = {};
      }
      if (!column.filter.props.placeholder) {
        column.filter.props.placeholder = 'Filtrar...';
      }
    }
  })

  const pagination = paginationFactory({
    page: page,
    sizePerPage: pageSize,
    lastPageText: '>>',
    firstPageText: '<<',
    totalSize: totalSize,
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    paginationTotalRenderer: function (from, to, size) {
      return <div>Mostrando desde {from} hasta {to}, de {size} resultados </div>
    },
    onPageChange: setPage,
    onSizePerPageChange: setPageSize,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="btn-group" role="group">
        {
          options.map((option) => {
            const isSelect = currSizePerPage === `${option.page}`;
            return (
              <button
                key={option.text}
                type="button"
                onClick={() => onSizePerPageChange(option.page)}
                className={`btn ${isSelect ? 'btn-secondary' : 'btn-primary'}`}
              >
                {option.text}
              </button>
            );
          })
        }
      </div>
    )
  });

  return <BootstrapTable keyField='id'
    headerClasses="admin-table-header align-top"
    hover
    data={data}
    columns={columns}
    pagination={pagination}
    filter={filterFactory()}
    onTableChange={onTableChange || (() => null)}
    remote={{ filter: true, pagination: true }}
  />

}

export default AdminTable;