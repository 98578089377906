import React from "react";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { EditionContext } from "../../../../contexts/editionContext";
import CaratulaSelector from "../../../shared/caratula-selector";
import NovedadesService from "../../../../services/novedades-service";
import AdminTable from "../../../shared/admin-table";
import { customFilter } from "react-bootstrap-table2-filter";
import ClasificadorLegajoSelector from "../../../shared/clasificador-legajo-selector";
import { BsPencil, BsPlusLg, BsTrash } from "react-icons/bs";
import { UIContext } from "../../../../contexts/uiContext";

function NovedadesList() {

  const { setEdicion, setObjetoEdicion } = React.useContext(EditionContext)
  const [novedadesPage, setNovedadesPage] = React.useState({ content: [] })
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [caratula, setCaratula] = React.useState(null)
  const [clasificadorLegajo, setClasificadorLegajo] = React.useState(null)
  const [showDeleteNovedadModal, setShowDeleteNovedadModal] = React.useState(false);
  const [novedadSeleccionadaParaBorrar, setNovedadSeleccionadaParaBorrar] = React.useState(null)
  const { showMessage } = React.useContext(UIContext)
  const mainDivRef = React.useRef(null);

  function modificarNovedad(novedad) {
    NovedadesService.getNovedad(novedad.id).then(novedad => {
      setObjetoEdicion(novedad)
      setEdicion(true)
    });
  }

  React.useEffect(() => {
    buscar()
  }, [page, pageSize, caratula?.id, clasificadorLegajo])


  function buscar() {
    NovedadesService.getNovedades(page, pageSize, caratula?.id, clasificadorLegajo).then(data => {
      setNovedadesPage(data)
    })
  }

  function handleSucces(message) {
    showMessage(message, 'success');
  }

  function btnDeleteNovedad(novedad) {
    setNovedadSeleccionadaParaBorrar(novedad);
    setShowDeleteNovedadModal(true);
  }

  function deleteNovedad() {
    NovedadesService.deleteNovedad(novedadSeleccionadaParaBorrar.id).then(data => {
      handleSucces('Novedad eliminada con éxito');
      setShowDeleteNovedadModal(false);
      buscar();
    }).catch(error => {
      showMessage(error.response.data.error, 'error')
      setShowDeleteNovedadModal(false);
    });
  }

  const columns = [
    {
      dataField: 'caratula.nombre',
      text: 'Caratula',
      filter: customFilter(),
      filterRenderer: () =>
        <CaratulaSelector value={caratula} onSelect={(newCaratula) => {
          if (newCaratula) {
            setCaratula(newCaratula)
          } else {
            setCaratula(null)
          }
        }} />
    },
    {
      dataField: 'clasificadorLegajo',
      text: 'Clasificador Legajo',
      filter: customFilter(),
      filterRenderer: () =>
        <ClasificadorLegajoSelector value={clasificadorLegajo} onSelect={(newClasificadorLegajo) => {
          if (newClasificadorLegajo) {
            setClasificadorLegajo(newClasificadorLegajo)
          } else {
            setClasificadorLegajo(null)
          }
        }} />
    },
    {
      isDummyField: true,
      text: 'Acciones',
      width: '20px',
      formatter: (cell, row, rowIndex) => {
        return <>
          <Button style={{ marginRight: '5px' }} onClick={() => modificarNovedad(row)} size="sm"
            title="Modificar">
            <BsPencil />
          </Button>
          <Button onClick={() => btnDeleteNovedad(row)} size="sm"
            title="Eliminar">
            <BsTrash />
          </Button>

        </>
      },
    },
  ];

  return (
    <div ref={mainDivRef}>
      <Container className="central-div" >
        <div className="title-and-buttons">
          <h1 className="title">Novedades</h1>
          <span className="buttons">
            <Button onClick={() => { setEdicion(true); setObjetoEdicion(null) }}
              title="Nueva Novedad"
            >
              <BsPlusLg />
            </Button>
          </span>
        </div>
      </Container>
      <Container className="central-div">
        <Row>
          <AdminTable data={novedadesPage.content}
            columns={columns}
            totalSize={novedadesPage.totalElements}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </Row>
      </Container>

      <Modal show={showDeleteNovedadModal}
        onHide={() => setShowDeleteNovedadModal(false)} animation={false} container={mainDivRef.current}
      >
        <Modal.Header closeButton>
          <Modal.Title>Borrar novedad</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>¿Está seguro que quiere borrar novedad?</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteNovedadModal(false)}>
            Cerrar
          </Button>
          <Button variant="danger" onClick={() => deleteNovedad()}>
            Eliminar Novedad
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default NovedadesList;