import moment from "moment";
import React from "react";
import { Button, Container, Row } from "react-bootstrap";
import { EditionContext } from "../../../../contexts/editionContext";
import ParametroConstanteService from "../../../../services/parametro-constante-service";
import AdminTable from "../../../shared/admin-table";
import { BsPencil, BsPlusLg } from 'react-icons/bs';

function dataFormater(value) {
  return moment(value).format('DD/MM/YYYY')
}

function ParametroConstanteList() {
  const { setEdicion, setObjetoEdicion } = React.useContext(EditionContext);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [parametroConstantePage, setParametroConstantePage] = React.useState({ content: [] })

  React.useEffect(() => {
    ParametroConstanteService.getParametrosConstantes(page, pageSize).then(data => {
      setParametroConstantePage(data)
    })
  }, [page, pageSize])

  function modificarParametroConstante(parametroConstante) {
    const objetoEdicion = Object.assign({}, parametroConstante);
    setObjetoEdicion(objetoEdicion);
    setEdicion(true)
  }

  const columns = [
    { dataField: 'nombre', text: 'Nombre' },
    { dataField: 'valorVigente', text: 'Valor Actual' },
    { dataField: 'vigenteHasta', formatter: dataFormater, text: 'Vigente hasta' },
    {
      isDummyField: true,
      text: 'Modificar',
      width: '20px',
      formatter: (cell, row, rowIndex) => {
        return <>
          <Button onClick={() => modificarParametroConstante(row)} size="sm"
            title="Modificar">
            <BsPencil />
          </Button>
        </>
      },
    },
  ];

  return (
    <div>
      <Container className="central-div" >
        <div className="title-and-buttons">
          <h1 className="title">Parametros Constantes</h1>
          <span className="buttons">
            <Button onClick={() => { setEdicion(true); setObjetoEdicion(null) }}
              title="Nuevo Legajo"
            >
              <BsPlusLg />
            </Button>
          </span>
        </div>
      </Container>
      <Container className="central-div">
        <Row>
          <AdminTable data={parametroConstantePage.content}
            columns={columns}
            totalSize={parametroConstantePage.totalElements}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </Row>
      </Container>
    </div >
  )
}
export default ParametroConstanteList;
