import React from "react";
import { EditionContext } from "../../../contexts/editionContext";
import GeneradorArchivoList from "./list";
import GeneradorArchivoNew from "./new";

function GeneradorArchivo() {
  const { edicion } = React.useContext(EditionContext);

  return (
    edicion === true ?
      <GeneradorArchivoNew /> : <GeneradorArchivoList />
  )
}

export default GeneradorArchivo;