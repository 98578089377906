import React from "react";
import { Row, Form } from "react-bootstrap";


function SiNo(props) {
  const options = [
    {
      label: '',
      value: ''
    },
    {
      label: "Si",
      value: true
    },
    {
      label: "No",
      value: false
    }
  ];

  return (
    <div>
      {props.dropdown ?
        <div>
          <input id={props.id} type={"checkbox"} checked={props.value} onClick={e => {
            props.onSelect(e.target.checked)
          }} />
        </div> :
        <Form.Select value={props.value}
          onChange={e => {
            props.onSelect(e.target.value)
          }}>
          {options.map(option =>
            <option value={option.value}>{option.label}</option>
          )}
        </Form.Select>
      }
    </div>
  )
}

export default SiNo;