import React from 'react';
import { Route, Link, Routes, useNavigate } from "react-router-dom";
import { BsPersonCircle } from "react-icons/bs";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from "react-bootstrap/Nav";
import Authentication from './services/authentication-service';
import { UserContext } from './contexts/userContext'
import * as FlexLayout from "flexlayout-react";
import Login from './components/shared/login';
import { EditionProvider } from './contexts/editionContext'
import Legajo from './components/pages/legajo';
import GeneradorArchivo from './components/pages/generador-archivo';
import ConceptoLiquidacion from './components/pages/concepto-liquidacion';
import Categoria from './components/pages/categoria';
import Caratula from './components/pages/caratula';
import Liquidacion from './components/pages/liquidacion';
import Novedades from './components/pages/novedades';
import ParametroConstante from './components/pages/parametro-constante'
import AutoImpresion from './components/pages/auto-impresion';
import './App.css';


const json = {
  global: {
    tabEnableClose: true,
    tabSetEnableMaximize: false,
    tabEnableRename: false
  },
  layout: {
    "type": "row",
    "weight": 100,
    "children": [
      {
        "type": "tabset",
        "weight": 100,
        "selected": 0,
        "id": "main_tabset",
        "children": []
      },
    ]
  }
};

function App() {

  const { user, setUser } = React.useContext(UserContext)
  const navigate = useNavigate();
  const [model, setModel] = React.useState(FlexLayout.Model.fromJson(json))
  const layoutRef = React.useRef(null);

  const logout = () => {
    setUser(null)
    Authentication.logout()
    navigate('/')
  }

  const addTab = (id, name) => {
    if (layoutRef.current.tabIds.includes(id)) {
      model.doAction(FlexLayout.Actions.selectTab(id));
    } else {
      layoutRef.current.addTabToActiveTabSet(
        {
          type: "tab",
          component: id,
          name,
          id
        }
      )
    }
  }

  function factory(node) {
    var component = node.getComponent();
    switch (component) {
      case "legajo":
        return <EditionProvider> <Legajo /></EditionProvider >
      case "conceptoLiquidacion":
        return <EditionProvider> <ConceptoLiquidacion /> </EditionProvider>
      case "categoria":
        return <EditionProvider> <Categoria /> </EditionProvider>
      case "caratula":
        return <EditionProvider> <Caratula /> </EditionProvider>
      case "liquidacion":
        return <EditionProvider><Liquidacion /></EditionProvider>
      case "novedades":
        return <EditionProvider><Novedades /></EditionProvider>
      case "parametroConstante":
        return <EditionProvider><ParametroConstante /></EditionProvider>
      case "generadorArchivo":
        return <EditionProvider><GeneradorArchivo /></EditionProvider>
    }

  }

  return (
    <div className="App">
      <header>
        <Navbar bg="dark" variant="dark">
          <Container >
            <Navbar.Brand>
              <Link to={"/"} className="nav-link">
                Liquidación de sueldos
              </Link>
            </Navbar.Brand>
            {user && <Nav className="justify-content-end">
              <Link to={"/"} size="sm"
                className="nav-link"
                onClick={(e) => {
                  e.preventDefault();
                  addTab("legajo", "Legajo")
                }}>
                Legajos
              </Link>
              <Link to={"/"} size="sm"
                className="nav-link"
                onClick={(e) => {
                  e.preventDefault();
                  addTab("conceptoLiquidacion", "Concepto Liquidación");
                }}>
                Concepto liquidación
              </Link>
              <Link to={"/"} size="sm"
                className="nav-link"
                onClick={(e) => {
                  e.preventDefault();
                  addTab("categoria", "Categoría");
                }}>
                Categoría
              </Link>
              <Link to={"/"} size="sm"
                className="nav-link"
                onClick={(e) => {
                  e.preventDefault();
                  addTab("caratula", "Carátula");
                }}>
                Caratula
              </Link>
              <Link to={"/"} size="sm"
                className="nav-link"
                onClick={(e) => {
                  e.preventDefault();
                  addTab("liquidacion", "Liquidación");
                }}>
                Liquidacion
              </Link>
              <Link to={"/"} size="sm"
                className="nav-link"
                onClick={(e) => {
                  e.preventDefault();
                  addTab("novedades", "Novedades");
                }}>
                Novedades
              </Link>
              <Link to={"/"} size="sm"
                className="nav-link"
                onClick={(e) => {
                  e.preventDefault();
                  addTab("parametroConstante", "Parámetro Constante");
                }}>
                Parametro constante
              </Link>
              <Link to={"/"} size="sm"
                className='nav-link'
                onClick={(e) => {
                  e.preventDefault();
                  addTab("generadorArchivo", "Generador Archivos");
                }}>
                Generador Archivos
              </Link>
            </Nav>}
            {!user && <Link to={"/auto-impresion"} size="sm"
              className="nav-link">
              Impresion de Recibo
            </Link>}
            {user && <NavDropdown title={<div><BsPersonCircle />  {user.username} </div>}
              id="nav-dropdown"
              style={{ 'marginLeft': '40px' }}>
              <NavDropdown.Item onClick={logout} eventKey="4.1"> Salir </NavDropdown.Item>
            </NavDropdown>}
          </Container>
        </Navbar>
      </header>
      <Routes>
        <Route path="/" element={<div> {user ?
          <div className="tabs-container">
            <FlexLayout.Layout
              className="container-color"
              model={model}
              factory={factory}
              ref={layoutRef}
            />
          </div> : <Login />}
        </div>} />
        <Route path="/auto-impresion" element={<AutoImpresion />} />
      </Routes>
    </div>

  );
}
export default App;
