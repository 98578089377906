import React from 'react';
import Select from 'react-select';

function BaseDarkSelect(props) {

  const styles = {
    menuList: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: 'var(--color-6)'
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      color: 'inherit !important',
      border: '10px'
    }),
    singleValue: (baseStyles) => {
      return {
        ...baseStyles,
        color: 'inherit !important',
      }
    },
    control: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: 'var(--color-6)'
    }),
    option: (baseStyles, state) => {
      return {
        ...baseStyles,
        backgroundColor: state.isFocused ? 'var(--color-4)' :
          state.isSelected ? 'var(--color-3)' : 'var(--color-6)'
      }
    },
  }

  delete props.styles;

  return (
    <Select {...props} styles={styles} />
  )
}

export default BaseDarkSelect;