import React from 'react'
import { Button, Container, Form, FormLabel, Modal } from 'react-bootstrap';
import LegajoService from '../../../../services/legajo-service';
import CategoriaService from '../../../../services/categoria-service'
import Row from "react-bootstrap/Row";
import { EditionContext } from '../../../../contexts/editionContext'
import { UIContext } from '../../../../contexts/uiContext';
import AdminTable from '../../../shared/admin-table';
import { customFilter, textFilter, numberFilter, Comparator } from 'react-bootstrap-table2-filter';
import ClasificadorLegajoSelector from '../../../shared/clasificador-legajo-selector';
import { BsPencil, BsPlusLg, BsTrash } from 'react-icons/bs';
import DatePicker from '../../../shared/date-picker';

const emptyFormBaja = {
  fechaBaja: '',
  motivoBaja: ''
}

function LegajoList() {

  const { setEdicion, setObjetoEdicion } = React.useContext(EditionContext)
  const [legajosPage, setLegajosPage] = React.useState({ content: [] });
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);
  const [showDesactivarLegajoModal, setShowDesactivarLegajoModal] = React.useState(false)
  const { showMessage } = React.useContext(UIContext)
  const [legajoSeleccionadoParaDesactivar, setLegajoSeleccionadoParaDesactivar] = React.useState(null);
  const [filters, setFilters] = React.useState({});
  const mainDivRef = React.useRef(null);
  const [formBaja, setFormBaja] = React.useState(emptyFormBaja);

  React.useEffect(() => {
    buscarLegajos()
  }, [page, pageSize, filters.clasificadorLegajo, filters.apellido, filters.nombre, filters.numero, filters.dni]);

  function modificarLegajo(row) {
    (async () => {
      const legajo = Object.assign({}, row);
      const data = await LegajoService.getCategoriasByLegajoId(legajo.id);
      legajo.categorias = data.content;

      for (const eachCategoriaLegajo of legajo.categorias) {
        const relacionCategoria = eachCategoriaLegajo.relaciones.find(
          eachRelacion => eachRelacion.type === "categoria")

        const categoria = await CategoriaService.getCategoria(relacionCategoria.id);

        eachCategoriaLegajo.categoria = categoria;
      }


      if (legajo.valoresConceptoLiquidacion) {
        for (const eachValor of legajo.valoresConceptoLiquidacion) {
          const relacionConcepto = eachValor.relaciones.find(
            eachRelacion => eachRelacion.type === "concepto_liquidacion")

          eachValor.conceptoLiquidacion = {
            id: relacionConcepto.id,
            nombre: relacionConcepto.metadata.nombre,
            codigo: relacionConcepto.metadata.codigo
          }
          if (relacionConcepto.metadata.conceptosExcluyentes) {
            eachValor.conceptoLiquidacion.conceptosExcluyentes = relacionConcepto.metadata.conceptosExcluyentes.split(',').map(idConceptoExcluyente => ({ id: idConceptoExcluyente }))
          }
        }
      }
      setObjetoEdicion(legajo);
      setEdicion(true)
    })();
  }

  function buscarLegajos() {
    LegajoService.getLegajos(page, pageSize, filters)
      .then(data => setLegajosPage(data))
  }

  function onTableChange(type, newState) {
    if (type === 'filter') {
      setFilters(() => {
        const newFilter = {};
        for (const filterName in newState.filters) {
          const filterObject = newState.filters[filterName];
          const value = filterObject.filterType === 'NUMBER' ?
            filterObject.filterVal.number :
            filterObject.filterVal;
          if (value) {
            newFilter[filterName] = value;
          }
        }
        return newFilter;
      })
    }
    return null;
  }

  const columns = [
    {
      dataField: 'numero', text: 'Número', filter: numberFilter({
        comparators: [Comparator.EQ],
        defaultValue: { comparator: Comparator.EQ },
        comparatorStyle: { display: 'none' },
      })
    },
    {
      dataField: 'apellido',
      text: 'Apellido',
      filter: textFilter({
        style: { display: 'inline-grid' },
      }),
    },
    { dataField: 'nombre', text: 'Nombre', filter: textFilter() },
    {
      dataField: 'dni',
      text: 'DNI',
      filter: numberFilter({
        comparators: [Comparator.EQ],
        defaultValue: { comparator: Comparator.EQ },
        comparatorStyle: { display: 'none' },
      }),
    },
    { dataField: 'direccion', text: 'Dirección' },
    { dataField: 'numeroTelefono', text: 'Teléfono' },
    {
      dataField: 'clasificadorLegajo',
      text: 'Clasificador',
      classes: 'some-class',
      filter: customFilter(),
      filterRenderer: () =>
        <ClasificadorLegajoSelector value={filters.clasificadorLegajo}
          onSelect={(newClasificadorLegajo) => setFilters(currFilter => { return { ...currFilter, clasificadorLegajo: newClasificadorLegajo } })}
        />
    },
    {
      isDummyField: true,
      text: 'Modificar',
      width: '20px',
      formatter: (cell, row, rowIndex) => {
        return <>
          <Button onClick={() => modificarLegajo(row)} size="sm"
            title="Modificar">
            <BsPencil />
          </Button>
        </>
      },
    },
    {
      isDummyField: true,
      text: 'Inactivar',
      width: '20px',
      formatter: (cell, row, rowIndex) => {
        return <>
          <Button onClick={() => btnDesactivarAction(row)}
            size="sm"
            title="Desactivar"
            variant="danger">
            <BsTrash />
          </Button>
        </>
      },
    }
  ];

  function btnDesactivarAction(legajo) {
    setLegajoSeleccionadoParaDesactivar(legajo);
    setShowDesactivarLegajoModal(true);
  }

  function handleSucces(message) {
    showMessage(message, 'success');
  }

  function desactivarLegajo() {
    LegajoService.deactivateLegajo(legajoSeleccionadoParaDesactivar.id, formBaja).then(data => {
      handleSucces('Legajo desactivado con éxito');
      setShowDesactivarLegajoModal(false);
      buscarLegajos();
    });
  }

  const handleFormValue = (name, value) => {
    const newForm = {
      ...formBaja,
      [name]: value
    }
    setFormBaja(newForm)
  }

  const motivoBajaOptions = [
    { value: '', label: '' },
    { value: 'RENUNCIA', label: 'Renuncia' },
    { value: 'JUBILACION', label: 'Jubilación' },
    { value: 'CESANTIA', label: 'Cesantía' },
    { value: 'MUERTE', label: 'Muerte' },
    { value: 'EXONERACION', label: 'Exoneración' },
  ]

  return (
    <div ref={mainDivRef}>
      <Container className="central-div" >
        <div className="title-and-buttons">
          <h1 className="title">Legajos</h1>
          <span className="buttons">
            <Button onClick={() => { setEdicion(true); setObjetoEdicion(null) }}
              title="Nuevo Legajo"
            >
              <BsPlusLg />
            </Button>
          </span>
        </div>
      </Container>

      <Container className="central-div" >
        <Row>
          <AdminTable data={legajosPage.content}
            columns={columns}
            totalSize={legajosPage.totalElements}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            onTableChange={onTableChange}
          />
        </Row>
      </Container>

      <Modal show={showDesactivarLegajoModal} onHide={() => setShowDesactivarLegajoModal(false)} animation={false} container={mainDivRef.current}>
        <Modal.Header closeButton>
          <Modal.Title>Desactivar legajo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="central-div"
            autoComplete="off" >
            <h4>Fecha de baja</h4>
            <DatePicker
              selected={formBaja.fechaBaja}
              onChange={date => handleFormValue('fechaBaja', date)}
            />
            <h4>Motivo de baja</h4>
            <Form.Select
              className='select-dark'
              value={formBaja.motivoBaja}
              onChange={e => handleFormValue('motivoBaja', e.target.value)}>
              {motivoBajaOptions.map(option =>
                <option value={option.value}>{option.label}</option>
              )}
            </Form.Select>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDesactivarLegajoModal(false)}>
            Cerrar
          </Button>
          <Button variant="danger" onClick={() => desactivarLegajo()}>
            Desactivar legajo
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )

}
export default LegajoList;