import React from 'react';
import { UIContext } from '../../contexts/uiContext';
import Select from 'react-select';
import AreaService from '../../services/area-service';
import BaseDarkSelect from './base-dark-select';

function AreaSelector(props) {

  const [areasOptions, setAreasOptions] = React.useState([])
  const { showMessage } = React.useContext(UIContext);

  React.useEffect(() => {
    buscar()
  }, [])

  function buscar() {
    AreaService.getAreas()
      .then(data => setAreasOptions(data.map(area => ({ value: area, label: area.nombre }))))
      .catch(() => showMessage('Ocurrió u error al recuperar las áreas', 'error'))
  }

  return (
    <div>
      <BaseDarkSelect options={areasOptions}
        value={props.value && props.value.id}
        required
        isClearable
        onChange={e => {
          if (e?.value) {
            props.onSelect(e.value)

          } else {
            props.onSelect({})
          }
        }} />
    </div>
  )
}

export default AreaSelector;