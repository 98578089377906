import axios from "axios";

function fromServer(novedad) {

  if (novedad.relaciones) {
    const relacion = novedad.relaciones.find(relacion => relacion.type === "caratula")
    novedad.caratula = {
      nombre: relacion.metadata.nombre
    }
  }
  if (novedad.lineasNovedades) {
    novedad.lineasNovedades.forEach(lineaNovedad => {
      if (lineaNovedad.legajo?.fechaIngreso) {
        lineaNovedad.legajo.fechaIngreso = new Date(lineaNovedad.legajo.fechaIngreso)
      }
      if (lineaNovedad.valoresConceptoLiquidacion) {
        lineaNovedad.valoresConceptoLiquidacion.forEach(eachValor => {
          const relacionConcepto = eachValor.relaciones.find(
            eachRelacion => eachRelacion.type === "concepto_liquidacion")

          eachValor.conceptoLiquidacion = {
            id: relacionConcepto.id,
            nombre: relacionConcepto.metadata.nombre,
            codigo: relacionConcepto.metadata.codigo
          }
        });
      }
    })
  }
  return novedad
}

const NovedadesService = {
  getNovedades: (page, size, idCaratula, clasificadorLegajo) => {
    const params = {
      page: page ? page - 1 : 0,
      size: size ? size : 0
    }

    if (idCaratula) {
      params["caratula-id"] = idCaratula;
    }
    if (clasificadorLegajo) {
      params["clasificador-legajo"] = clasificadorLegajo;
    }
    return axios.get('/api/liquisueldos/novedades', { params }).then(resp => {
      resp.data.content.forEach(fromServer);
      return resp.data
    })
  },
  getNovedad: id => {
    return axios.get('/api/liquisueldos/novedades/' + id).then(resp => fromServer(resp.data))
  },
  createNovedad: novedad => {
    return axios.post('/api/liquisueldos/novedades', novedad).then(resp => resp.data)
  },
  updateNovedad: novedad => {
    return axios.put('/api/liquisueldos/novedades', novedad).then(resp => resp.data)
  },
  deleteNovedad: id => {
    return axios.delete('/api/liquisueldos/novedades/' + id).then(resp => resp.data);
  }
}

export default NovedadesService;