import axios from 'axios';

const GeneradorArchivoService = {

  getGeneradorArchivos: (page, size) => {
    const params = {
      page: page ? page - 1 : 0,
      size: size ? size : 10
    }
    return axios.get('/api/liquisueldos/generador-archivo', { params }).then(resp => resp.data);
  },
  getGeneradorArchivo: id => {
    return axios.get('/api/liquisueldos/generador-archivo/' + id).then(resp => resp.data);
  },
  createGeneradorArchivo: generadorArchivo => {
    return axios.post('/api/liquisueldos/generador-archivo', generadorArchivo).then(resp => resp.data);
  },
  updateGeneradorArchivo: generadorArchivo => {
    return axios.put('/api/liquisueldos/generador-archivo', generadorArchivo).then(resp => resp.data);
  },
  generarArchivo: (idCaratula, clasificadorLegajo, idNovedad, idGeneradorArchivo) => {
    const params = {}
    if (idCaratula) {
      params["caratula-id"] = idCaratula;
    }
    if (clasificadorLegajo) {
      params["clasificador-legajo"] = clasificadorLegajo;
    }
    if (idNovedad) {
      params["novedad-id"] = idNovedad;
    }
    if (idGeneradorArchivo) {
      params["generador-archivo-id"] = idGeneradorArchivo;
    }
    return axios.get('/api/liquisueldos/generador-archivo/generar-archivo', { params }).then(resp => resp.data);
  }
}

export default GeneradorArchivoService;