import axios from 'axios';

function fromServer(categoria) {

  if (categoria.sueldosBasicos) {
    categoria.sueldosBasicos.forEach(eachSueldo => {
      if (eachSueldo.fechaHasta) {
        eachSueldo.fechaHasta = new Date(eachSueldo.fechaHasta);
      }
    });
    categoria.sueldosBasicos.sort((a, b) => a.fechaHasta - b.fechaHasta);
  }
  return categoria;
}

const CategoriaService = {


  getCategorias: (page, size) => {
    const params = {
      page: page ? page - 1 : 0,
      size: size ? size : 10
    }
    return axios.get('/api/liquisueldos/categoria', { params }).then(resp => {
      resp.data.content.forEach(fromServer)
      return resp.data
    });
  },
  getCategoria: id => {
    return axios.get('/api/liquisueldos/categoria/' + id).then(resp => fromServer(resp.data));
  },
  createCategoria: categoria => {
    return axios.post('/api/liquisueldos/categoria', categoria).then(resp => resp.data);
  },
  updateCategoria: categoria => {
    return axios.put('/api/liquisueldos/categoria', categoria).then(resp => resp.data);
  }

}

export default CategoriaService;