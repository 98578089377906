import React from 'react';
import { UIContext } from '../../contexts/uiContext';
import CategoriaService from '../../services/categoria-service';
import { FaUndo } from 'react-icons/fa';
import BaseDarkSelect from './base-dark-select';

function CategoriaSelector(props) {
  const [categoriasOptions, setCategoriasOptions] = React.useState([])
  const { showMessage } = React.useContext(UIContext)

  React.useEffect(() => {
    buscar()
  }, [])

  function buscar() {
    CategoriaService.getCategorias(1, 500)
      .then(page => {
        setCategoriasOptions(page.content.map(categoria => ({ value: categoria, label: categoria.nombre })))
      })
      .catch((error) => {
        showMessage('Ocurrió un error al recuperar las categorías', 'error')
      })
  }

  return (
    <BaseDarkSelect options={categoriasOptions}
      value={{ value: props.value, label: props.value.nombre }}
      required
      onChange={e => props.onSelect(e.value)} />
  )
}
export default CategoriaSelector;