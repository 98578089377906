import Authentication from './authentication-service';

function addLeadingZero(value) {
  if (value.length === 1) {
    return '0' + value;
  }
  return value;
}

const UtilsService = {
  formatDateToServer: date => {
    const hour = addLeadingZero(date.getHours().toString())
    const minutes = addLeadingZero(date.getMinutes().toString())
    const day = addLeadingZero(date.getDate().toString())
    const month = addLeadingZero((date.getMonth() + 1).toString())
    return `${date.getFullYear()}-${month}-${day}T${hour}:${minutes}`
  },

  getUrlDataFromPdfEndpoint: (url, method = "GET", body = null) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open(method, url);
      xhr.responseType = 'blob';
      if (Authentication.getAuthToken()) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + Authentication.getAuthToken());
      }

      xhr.onreadystatechange = (e) => {
        if (e.target.readyState === 4) {
          if (e.target.status === 200) {
            const dataUrl = URL.createObjectURL(e.target.response);
            resolve(dataUrl);
            setTimeout(() => {
              URL.revokeObjectURL(dataUrl);
            }, 10000);
          } else {
            reject('Error getting the PDF');
          }
        }
      }
      if (body) {
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify(body));
      } else {
        xhr.send();
      }
    })
  },
  lettersOnly: (value) => {
    if (value) {
      return value.replace(/[^A-Za-z ]/g, '');
    }
    return 0;
  },
  numbersOnly: (value) => {
    if (value) {
      return value.replace(/[^\d.]/g, '');
    }
    return 0;
  }
}

export default UtilsService