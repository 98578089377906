import React from "react";
import { UIContext } from "../../contexts/uiContext";
import NovedadesService from "../../services/novedades-service";
import BaseDarkSelect from "./base-dark-select";

function NovedadesSelector(props) {

  const [novedadesOptions, setNovedadesOptions] = React.useState(null);
  const { showMessage } = React.useContext(UIContext);

  React.useEffect(() => {
    buscar()
  }, [])

  function buscar() {
    NovedadesService.getNovedades(1, 500)
      .then(page => {
        setNovedadesOptions(page.content.map(novedad => ({ value: novedad, label: novedad.caratula.nombre })))
      })
      .catch((error) => {
        showMessage('Ocurrió un error al recuperar las novedades.')
      })
  }

  return (
    <BaseDarkSelect options={novedadesOptions}
      value={{ value: props.value, label: props.value ? props.value.caratula.nombre : '' }}
      required
      isClearable
      onChange={e => {
        props.onSelect(e?.value || null)
      }} />
  )
}

export default NovedadesSelector;