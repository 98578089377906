import axios from 'axios';

import Storage from './storage-service'

const AUTH_TOKEN_KEY = 'authenticationToken';

const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }
};

const Authentication = {
  login: (username, password) => {
    return axios.post('/api/authenticate', { username, password }).then(resp => {
      if (resp.data && resp.data.access_token) {
        Storage.local.set(AUTH_TOKEN_KEY, resp.data.access_token);
      }
    }).catch(error => {
      console.error(error)
    })
  },
  logout: () => {
    clearAuthToken()
  },
  getSession: () => {
    return axios.get('/api/identify-account').then(resp => resp.data)
  },
  getAuthToken: () => {
    return Storage.local.get(AUTH_TOKEN_KEY)
  }
}

export default Authentication
