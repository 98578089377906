import moment from 'moment';
import React from 'react';
import { Button, Container } from 'react-bootstrap';
import Row from "react-bootstrap/Row";
import { BsPencil, BsPlusLg } from 'react-icons/bs';
import { EditionContext } from '../../../../contexts/editionContext';
import CaratulaService from '../../../../services/caratula-service';
import AdminTable from '../../../shared/admin-table';

function dataFormater(value) {

  return moment(value).format('DD/MM/YYYY')
}

function CaratulaList() {

  const { setEdicion, setObjetoEdicion } = React.useContext(EditionContext)
  const [caratulaPage, setCaratulaPage] = React.useState({ content: [] });
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  React.useEffect(() => {
    CaratulaService.getCaratulas(page, pageSize).then(data => { setCaratulaPage(data) })
  }, [page, pageSize])

  const columns = [
    { dataField: 'descripcion', text: 'Descripción' },
    { dataField: 'fechaPago', formatter: dataFormater, text: 'Fecha de pago' },
    { dataField: 'observaciones', text: 'Observaciones' },
    { dataField: 'infoDeAportes', text: 'Información de aportes' },

    {
      isDummyField: true,
      text: 'Modificar',
      formatter: (cell, row, rowIndex) => {
        return <Button onClick={() => {
          const objeto = Object.assign({}, row);
          setObjetoEdicion(objeto);
          setEdicion(true)
        }}
          title='Modificar'
          size='sm'
        >
          <BsPencil />
        </Button>
      }
    }
  ];

  return (
    <div>
      <Container className="central-div" >
        <div className="title-and-buttons">
          <h1 className="title">Carátulas</h1>
          <span className="buttons">
            <Button onClick={() => { setEdicion(true); setObjetoEdicion(null) }}
              title="Nueva Carátula"
            >
              <BsPlusLg />
            </Button>
          </span>
        </div>
      </Container>
      <Container className="central-div">
        <Row>
          <AdminTable data={caratulaPage.content}
            columns={columns}
            totalSize={caratulaPage.totalElements}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </Row>
      </Container>
    </div>
  )
}

export default CaratulaList;