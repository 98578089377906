import React from "react";
import { EditionContext } from "../../../contexts/editionContext";
import ParametroConstanteList from "./list";
import ParametroConstanteNew from "./new";

function ParametroConstante() {
  const { edicion } = React.useContext(EditionContext);

  return (
    edicion === true ?
      <ParametroConstanteNew /> : <ParametroConstanteList />
  )
}

export default ParametroConstante;