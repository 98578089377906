import React from "react";
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";

import { es } from "date-fns/locale";

registerLocale("es", es)

console.log("registering locale");

function DatePicker(props) {
  return <ReactDatePicker
    className="date-picker-input"
    showMonthDropdown
    showYearDropdown
    dropdownMode="select"
    dateFormat='dd/MM/yyyy'
    locale="es"
    {...props}
  />

}

export default DatePicker;
