import React from "react";
import { Button, Col, Container as Card, Form, FormLabel, Modal, Row, Table } from 'react-bootstrap';
import { EditionContext } from "../../../../contexts/editionContext";
import ConceptoLiquidacionSelector from "../../../shared/concepto-liquidacion-selector";
import { MdCheckCircle, MdDangerous } from "react-icons/md";
import detectEOL from 'detect-eol';

let fileReader;
let originalFileContent;

const emptyForm = {
  identificador: 'dni',
  columnaIdentificador: '',
  separadorCampos: '',
  columnaValorModificar: '',
  valorModificar: 'IMPORTE'
}

function ImportadorArchivos(props) {

  const formRef = React.useRef(null);
  const [form, setForm] = React.useState(emptyForm)
  const [fileContent, setFileContent] = React.useState([]);
  const [columnasOptions, setColumnasOptions] = React.useState([])

  const handleFormValue = (name, value) => {
    const newForm = {
      ...form,
      [name]: value
    }
    setForm(newForm)
  }

  function matchearLineasNovedades(newFileContent) {

    const identificador = form.identificador
    const columnaIdentificador = form.columnaIdentificador

    if (columnaIdentificador && identificador) {
      newFileContent.forEach(arreglo => {
        const lineaNovedadCoincidente = props.lineasNovedades.find(linea => linea.legajo[identificador] == arreglo[columnaIdentificador].trim())
        arreglo[0] = lineaNovedadCoincidente
      });
    }
  }

  function submitArchivo() {

    const columnaValorModificar = form.columnaValorModificar;
    const valorModificar = form.valorModificar;

    const newLineasNovedades = props.lineasNovedades.map(lineaNovedad => {
      const arregloEncontrado = fileContent.find(arreglo => {
        return arreglo[0]?.legajo.id === lineaNovedad.legajo.id
      })

      if (arregloEncontrado) {
        let valorConceptoLiquidacion = lineaNovedad.valoresConceptoLiquidacion
          .find(valor => valor.conceptoLiquidacion.id === form.conceptoLiquidacion.id);

        if (!valorConceptoLiquidacion) {
          valorConceptoLiquidacion = {
            conceptoLiquidacion: form.conceptoLiquidacion
          }
          lineaNovedad.valoresConceptoLiquidacion.push(valorConceptoLiquidacion);
        }
        switch (valorModificar) {
          case "IMPORTE":
            valorConceptoLiquidacion.importe = +arregloEncontrado[columnaValorModificar].replaceAll(',', '.')
            break;
          case "PORCENTAJE":
            valorConceptoLiquidacion.porcentaje = +arregloEncontrado[columnaValorModificar].replaceAll(',', '.')
            break;
          case "CANTIDAD":
            valorConceptoLiquidacion.cantidad = +arregloEncontrado[columnaValorModificar].replaceAll(',', '.')
            break;
          default:
            break;
        }
      }
      return lineaNovedad;
    })
    props.onSubmitLineasNovedades(newLineasNovedades)
  }


  const identificadorOptions = [
    { value: 'dni', label: 'DNI' },
    { value: 'cuil', label: 'CUIL' },
    { value: 'numero', label: 'Número legajo' }
  ];

  const valorOptions = [
    { value: 'IMPORTE', label: 'Importe' },
    { value: 'PORCENTAJE', label: 'Porcentaje' },
    { value: 'CANTIDAD', label: 'Cantidad' }
  ]

  React.useEffect(() => {

    if (form.separadorCampos && fileContent?.length > 0) {
      const newFileContent = originalFileContent.map(subArray => ["", ...subArray[1].split(form.separadorCampos)])
      matchearLineasNovedades(newFileContent)
      setFileContent(newFileContent)
    } else {
      setFileContent(originalFileContent)
    }
  }, [form.separadorCampos, form.identificador, form.columnaIdentificador, form.conceptoLiquidacion, originalFileContent])

  React.useEffect(() => {
    calcularColumnasOptions()
  }, [fileContent])

  function handleFileRead(e) {
    const content = fileReader.result;
    const eol = detectEOL(content);
    const lines = content.split(eol).filter(line => line.trim()).map(line => ["", line]);
    originalFileContent = lines;
    setFileContent(lines)
  }

  function handleFileChosen(file) {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  }

  function disableImportarButton() {
    return !form.identificador || !form.columnaIdentificador ||
      !form.separadorCampos || !form.columnaValorModificar ||
      !form.valorModificar || !form.conceptoLiquidacion || !originalFileContent
  }

  function calcularColumnasOptions() {
    if (!fileContent || !fileContent.length || !fileContent[0].slice(1)) {
      setColumnasOptions([])
    } else {
      setColumnasOptions(fileContent[0].slice(1)
        .map((field, index) => ({ value: index + 1, label: index + 1 })))
    }
  }

  function clearForm() {
    setForm(emptyForm)
    setFileContent([])
    originalFileContent = null;
  }

  return (
    <div>
      <Modal
        size="lg"
        show={props.showModal}
        animation={false} container={props.mainDivRef.current}
        onShow={clearForm}
        oHide={() => props.setShowModal(false)}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Card className="central-div">
            <h3>Importador de archivos</h3>
            <Form
              ref={formRef}
              className="central-div"
              autoComplete="off">
              <Row>
                <Col md={9}>
                  <Form.Group>
                    <FormLabel>Archivo</FormLabel>
                    <Form.Control type="file" name="file" required
                      onChange={e => {
                        handleFileChosen(e.target.files[0])
                      }} />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <FormLabel>Separador de campos</FormLabel>
                    <Form.Control type="text" name="separadorCampos" value={form.separadorCampos} required
                      onChange={e => handleFormValue('separadorCampos', e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="identificador">
                    <FormLabel>Tipo de identificador</FormLabel>
                    <Form.Select
                      className='select-dark'
                      value={form.identificador}
                      onChange={e => handleFormValue('identificador', e.target.value)}>
                      {identificadorOptions.map(option =>
                        <option value={option.value}>{option.label}</option>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <FormLabel>Columna del {form.identificador.toUpperCase()}</FormLabel>
                    <Form.Select value={form.columnaIdentificador}
                      onChange={e => handleFormValue('columnaIdentificador', e.target.value)}>
                      <option value={''}></option>
                      {columnasOptions.map(option =>
                        <option value={option.value}>{option.label}</option>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <FormLabel>Columna del valor</FormLabel>
                    <Form.Select value={form.columnaValorModificar}
                      onChange={e => handleFormValue('columnaValorModificar', e.target.value)}>
                      <option value={''}></option>
                      {columnasOptions.map(option =>
                        <option value={option.value}>{option.label}</option>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <Form.Group>
                    <Form.Label>Seleccionar concepto</Form.Label>
                    <ConceptoLiquidacionSelector
                      tipoLiquidacion={props.caratula?.tipoLiquidacion}
                      value={form.conceptoLiquidacion}
                      onSelect={newConceptoLiquidacion => handleFormValue('conceptoLiquidacion', newConceptoLiquidacion)} />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <FormLabel>Valor a modificar</FormLabel>
                    <Form.Select
                      className='select-dark'
                      value={form.valorModificar}
                      onChange={e => handleFormValue('valorModificar', e.target.value)}>
                      {valorOptions.map(option =>
                        <option value={option.value}>{option.label}</option>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card style={{ overflowX: "auto", overflowY: "auto", maxHeight: "500px" }}>
            {fileContent?.length && <Table bordered size="sm" >
              <thead>
                <tr>
                  <th>Legajo encontrado</th>
                  {fileContent[0].slice(1).map((field, index) => <th>{index + 1}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {fileContent.map((eachLine, indiceLinea) => {
                  return <tr key={indiceLinea}>
                    {eachLine.map((campo, indiceCampo) => {
                      if (indiceCampo === 0) {
                        return <td>{campo ? <div>{campo.legajo.nombre} <MdCheckCircle style={{ color: '#198754' }} /> </div> : <MdDangerous style={{ color: '#b9434e' }} />}</td>
                      } else if (indiceCampo == form.columnaValorModificar) {
                        return <td style={{ backgroundColor: '#198754' }}>{campo}</td>
                      } else {
                        return <td>{campo}</td>
                      }
                    })}
                  </tr>
                })}
              </tbody>
            </Table>}
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={submitArchivo} disabled={disableImportarButton()}>
            Importar
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}

export default ImportadorArchivos;