import React from "react";
import { EditionContext } from "../../../contexts/editionContext";
import NovedadesList from "./list";
import NovedadesNew from "./new";

function Novedades() {
  const { edicion } = React.useContext(EditionContext);

  return (
    edicion === true ?
      <NovedadesNew /> : <NovedadesList />
  )
}

export default Novedades;